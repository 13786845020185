import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNotifications } from '../context/NotificationProvider';
import CategoryService from '../services/category.service';
import ProductService from '../services/product.service';
import useAuth from '../hooks/useAuth';
import remove from '../assets/images/remove.png';
import people from '../assets/images/people.png';

function FlyProService() {
  const [domains, setDomains] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedServices, setSelectedServices] = useState([
    { domain: null, category: null, subCategory: null, product: null, cat_has_products: false, sub_cat_has_products: false }
  ]);
  const [selectedTherapist, setSelectedTherapist] = useState([]);
  const [address, setAddress] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [showDescriptionFor, setShowDescriptionFor] = useState({
  });
  const [showProductList, setShowProductList] = useState({

  });

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const notify = useNotifications();
  const { auth } = useAuth();

  useEffect(() => {
    const storedState = sessionStorage.getItem('state');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      setSelectedTherapist(parsedState.selectedTherapist || []);
      setAddress(parsedState.address || null);
    }
  }, []);

  const fetchDomains = async () => {
    try {
      if (!selectedTherapist.length) {
        console.warn('No therapist selected');
        return;
      }

      const domainIds = [...new Set(selectedTherapist[0].categories.map(cat => cat.domain_id).filter(id => id !== null))];
      console.log('Fetching domains for unique category IDs:', domainIds);

      const domainResponses = await Promise.all(
        domainIds.map(id =>
          axiosPrivate.get(`/domains/public/${id}`, {
            params: {
              with: 'media',
            },
            headers: { Authorization: `Bearer ${auth?.accessToken}` },
          })
        )
      );

      const filteredDomains = domainResponses.map(response => response.data);
      setDomains(filteredDomains);
    } catch (error) {
      console.error('Error fetching domains:', error);
      notify.error('Failed to load domains. Please try again later.');
    }
  };

  const fetchCategories = async (domainId) => {
    try {
      const response = await CategoryService.index(
        { domain_id: domainId, parent_id: null, is_active: 1, with: 'media' },
        axiosPrivate,
        auth?.accessToken ? false : true
      );
      setCategories(response);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await axiosPrivate.get(`/categories/public`, {
        params: { parent_id: categoryId, is_active: 1, with: 'media' }
      });
      setSubCategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const fetchProducts = async (categoryId) => {
    try {
      const response = await ProductService.index(
        { category_id: categoryId, with: 'media', is_active: 1 },
        axiosPrivate,
        auth?.accessToken ? false : true
      );
      setProducts(response);
      return response;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    return [];
  };

  useEffect(() => {
    if (!auth || Object.keys(auth).length === 0) {
      sessionStorage.setItem('redirectAfterLogin', '/fav-flypro');
      navigate('/login');
    } else {
      fetchDomains();
    }
  }, [auth, navigate, selectedTherapist]);

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  const handleDomainSelect = async (personIndex, domainObj) => {
    const updated = [...selectedServices];
    updated[personIndex] = { domain: domainObj, category: null, subCategory: null, product: null };
    setSelectedServices(updated);
    setShowDescriptionFor({ type: null, id: null });
    await fetchCategories(domainObj.id);
    setSubCategories([]);
    setProducts([]);
  };

  const handleCategorySelect = async (personIndex, categoryObj) => {
    const updated = [...selectedServices];
    updated[personIndex].category = categoryObj;
    updated[personIndex].subCategory = null;
    updated[personIndex].product = null;
    updated[personIndex].cat_has_products = true;
    setSelectedServices(updated);
    setShowDescriptionFor({ type: null, id: null });
    const products = await fetchProducts(categoryObj.id);
    console.log({ products })
    if (products.length > 0) {
      setShowProductList({ type: 'category', id: categoryObj.id })
    } else {
      const subcats = await CategoryService.index({ parent_id: categoryObj.id, is_active: 1, with: 'media' }, axiosPrivate, auth?.accessToken ? false : true);
      setSubCategories(subcats);
      updated[personIndex].cat_has_products = false;
    }
  };

  const handleSubCategorySelect = async (personIndex, subcatObj) => {
    const updated = [...selectedServices];
    updated[personIndex].subCategory = subcatObj;
    updated[personIndex].product = null;
    updated[personIndex].sub_cat_has_products = true;
    setSelectedServices(updated);
    const products = await fetchProducts(subcatObj.id);
    if (products.length > 0) {
      setShowProductList({ type: 'subCategory', id: subcatObj.id })
    } else {
      updated[personIndex].sub_cat_has_products = false;
    }
  };

  const handleProductSelect = (personIndex, prodObj) => {
    const updated = [...selectedServices];
    updated[personIndex].product = prodObj;
    setSelectedServices(updated);
  };

  const addPerson = () => {
    if (selectedServices.length < 4) {
      setSelectedServices([...selectedServices, { domain: null, category: null, subCategory: null, product: null }]);
    }
  };

  const removePerson = (idx) => {
    setSelectedServices(selectedServices.filter((_, i) => i !== idx));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedServices || selectedServices.some(service => !service.product)) {
      notify.warn('Merci de sélectionner un produit');
      return;
    }

    if (!selectedTherapist.length) {
      notify.warn("Veuillez sélectionner un thérapeute avant de continuer.");
      return;
    }

    if (!address) {
      notify.warn("Veuillez sélectionner une adresse avant de continuer.");
      return;
    }

    const paymentState = {
      selectedServices,
      isActive,
      selectedTherapist,
      address: address.address,
      // duo: selectedServices.length === 2 && isActive
    };

    sessionStorage.setItem('state', JSON.stringify(paymentState));
    navigate('/flypro-booking', { state: paymentState });
  };

  const toggleDescription = (type, id) => {
    setShowDescriptionFor(prev => prev.type === type && prev.id === id ? { type: null, id: null } : { type, id });
  };

  return (
    <>
      <Header />
      <div className={`container container-location row-${selectedServices.length}`}>
        <h2>Choisissez le type de soin</h2>
        <form onSubmit={handleSubmit}>
          <div className='content-row'>
            {selectedServices.map((person, idx) => (
              <div key={idx} className="person-services">
                {/* Select Domain */}
                {!person.domain && (
                  <div className="services-container">
                    {domains.map(dom => (
                      <div key={dom.id} className="service-wrapper">
                        <div
                          className="service-card"
                          style={{
                            background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${dom?.media[0]?.original_url}) no-repeat center center / cover`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                          }}


                        >
                          <div className="service-info">
                            <div className="service-text">
                              <h3>{dom.title}</h3>
                              <a className="more-info" onClick={() => toggleDescription("domain", dom.id)}>
                                Plus d'infos &gt;
                              </a>
                            </div>
                            <button
                              type="button"
                              className="select-button"
                              onClick={() => handleDomainSelect(idx, dom)}
                            >
                              Sélectionner
                            </button>
                          </div>
                        </div>
                        {showDescriptionFor.type === "domain" && showDescriptionFor.id === dom.id && <div className="service-description">{dom.description}</div>}
                      </div>
                    ))}
                  </div>
                )}

                {/* show category */}
                {person.domain && (!person.category || (person.category && person.cat_has_products)) && (
                  <div className="services-container">
                    {categories.map(cat => {
                      const isSelected = person.category?.id === cat.id;
                      return (
                        <div key={cat.id} className="service-wrapper">
                          <div
                            className={`service-card ${isSelected ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${cat?.media[0]?.original_url}) no-repeat center center / cover`,
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{cat.title}</h3>
                                <a className="more-info" onClick={() => toggleDescription("category", cat.id)}>
                                  Plus d'infos &gt;
                                </a>
                              </div>
                              <button
                                type="button"
                                className="select-button"
                                onClick={() => handleCategorySelect(idx, cat)}
                              >
                                Sélectionner
                              </button>
                              <button
                                type="button"
                                className={`btn-outline-primary selected`}
                              >
                                &gt;
                              </button>
                            </div>
                          </div>
                          {showDescriptionFor.type === "category" && showDescriptionFor.id === cat.id && <div className="service-description">{cat.description}</div>}
                          {showProductList.type === "category" && showProductList.id === cat.id && <div className='form-duree'>
                            <div className="form-group">
                              <div className="btn-group group-3" role="group">
                                {products.map(product => (
                                  <button
                                    type="button"
                                    key={product.id}
                                    className={`btn-default ${selectedServices[idx]?.product?.id === product.id ? 'btn-checked' : ''}`}
                                    onClick={() => handleProductSelect(idx, product)}
                                  >
                                    <span>{product.duration} min</span>
                                    <br />
                                    <span className='price'>{product.price} chf</span>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* show subCategories */}
                {person.domain && person.category && subCategories.length > 0 && (!person.subCategory || (person.subCategory && person.sub_cat_has_products)) && (
                  <div className="services-container">
                    {subCategories.map(sc => {
                      const isSelected = person.subCategory?.id === sc.id;
                      return (
                        <div key={sc.id} className="service-wrapper">
                          <div
                            className={`service-card ${isSelected ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0,0,0,0.8), rgba(0,0,0,0.5)), url(${sc?.media[0]?.original_url}) center/cover no-repeat`
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{sc.title}</h3>
                                <a className="more-info" onClick={() => toggleDescription("subCategory", sc.id)}>
                                  Plus d'infos &gt;
                                </a>
                              </div>
                              <button
                                type="button"
                                className="select-button"
                                onClick={() => handleSubCategorySelect(idx, sc)}
                              >
                                Sélectionner
                              </button>
                              <button
                                type="button"
                                className={`btn-outline-primary selected`}
                              >
                                &gt;
                              </button>
                            </div>
                          </div>
                          {showDescriptionFor.type === "subCategory" && showDescriptionFor.id === sc.id && <div className="service-description">{sc.description}</div>}
                          {showProductList.type === "subCategory" && showProductList.id === sc.id && <div className='form-duree'>
                            <div className="form-group">
                              <div className="btn-group group-3" role="group">
                                {products.map(product => (
                                  <button
                                    type="button"
                                    key={product.id}
                                    className={`btn-default ${selectedServices[idx]?.product?.id === product.id ? 'btn-checked' : ''}`}
                                    onClick={() => handleProductSelect(idx, product)}
                                  >
                                    <span>{product.duration} min</span>
                                    <br />
                                    <span className='price'>{product.price} chf</span>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='btn-grp'>
            <button type="button" onClick={addPerson} className="btn-add mt-4" disabled={selectedServices.length >= 4}>
              Ajouter une personne
            </button>
            <button type="submit" className="btn-next mt-4">
              Suivant
            </button>
          </div>

        </form>
      </div>
    </>
  );
}

export default FlyProService;
