import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../api/axios";
import { endpoints } from "../utils/utils";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import AppleLogin from "react-apple-login";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNotifications } from '../context/NotificationProvider';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import FacebookLogin from '@greatsumini/react-facebook-login';

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const notify = useNotifications();
  const emailVerificationStatus = searchParams.get('email_verification_status');

  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handleFacebookCallback = async (response) => {
    if (response?.status === "unknown") {
      console.error('Sorry!', 'Something went wrong with facebook Login.');
      return;
    }
    try {
      const res = await AuthService.socialLogin({ token: response.accessToken }, axiosPrivate, "facebook")
      afterLogin(res)
    } catch (error) {

    }

  }

  const appleResponse = async (response) => {
    if (!response.error) {
      try {
        const res = await AuthService.socialLogin({ token: response.authorization.id_token }, axiosPrivate, "apple")
        afterLogin(res)
      } catch (error) {

      }

    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [faClass, setFaClass] = useState("fa-regular fa-eye");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setFaClass("fa-regular fa-eye-slash");
      return;
    }
    setPasswordType("password");
    setFaClass("fa-regular fa-eye");
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await AuthService.socialLogin({ token: codeResponse.access_token }, axiosPrivate, "google")
        afterLogin(response)
      } catch (error) {

      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const afterLogin = (response) => {
    console.log("response login", response)
    if (response?.user?.status === 'ACTIVE' || response?.user?.status === 'PENDING_PHONE_VERIFICATION') {
      const accessToken = response?.token;
      const roles = response?.user?.roles;
      const status = response?.user?.status;
      const refresh = response?.refresh_token;
      const active = response?.user?.status === 'ACTIVE' || response?.user?.status === 'PENDING_PHONE_VERIFICATION';
      localStorage.setItem("accessToken", response?.token);
      console.log(accessToken);
      setAuth({ user: response?.user, pwd, roles, accessToken, refresh, active, status });
      setUser("");
      setPwd("");
      /* const redirect = roles.filter((element) => {
        return element === "ROLE_CLIENT";
      }); */
      const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')
      if (response?.user?.status === 'PENDING_PHONE_VERIFICATION') {
        navigate("/VerificationNumero", { replace: true });
      } else if (redirectAfterLogin) {
        navigate(redirectAfterLogin, { replace: true });
        sessionStorage.removeItem('redirectAfterLogin');
      }
      else {
        setTimeout(() => {
          UserService.update(response?.user?.id, { fcm_token_web: localStorage.getItem("firebaseToken") }, axiosPrivate)
        }, 3000);

        navigate("/", { replace: true });
        console.log(response);
      }
    } else setErrMsg("Votre compte n'est pas actif");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    /*  console.log("params", params.id); */

    try {
      const response = await AuthService.login({ email: user, password: pwd }, axiosPrivate);
      console.log("data", response)
      afterLogin(response)

    } catch (err) {
      //errRef.current.focus();

    }
  };



  return (
    <div className="main-login">
      <div className="container">
        <div className="admin-left">
          <div className="logo">Flyspa</div>
          <h2>Se connecter</h2>
          <form name="" id="" onSubmit={handleSubmit}>
            {errMsg ? <p className="error">{errMsg}</p> : ""}
            <div className="form-text">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Name@mail.com"
                className="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
            </div>
            <div className="form-text">
              <input
                type={passwordType}
                onChange={(e) => setPwd(e.target.value)}
                name="password"
                id="password"
                value={pwd}
                placeholder="Password"
                className="password"
                required
              />
              <div onClick={togglePassword} className="eye">
                <a
                  href="#0"
                  onClick={togglePassword}
                  role="button"
                  tabIndex="0"
                >
                  <i onClick={togglePassword} className={faClass}></i>
                </a>
              </div>
            </div>
            <a href="#0" className="pass-oublie">Mot de passe oublié ?</a>
            <div className="form-submit">
              <input type="submit" name="sign" id="sign" value="Connexion" />
            </div>
          </form>
          <div className="form-login">
            <div className="text-ou">ou</div>
            <div className="sign-apple-google">
              <AppleLogin
                clientId="digital.pulse.flyspaweb"
                redirectURI={process.env.REACT_APP_APPLE_REDIRECT}
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button className="login-with-apple-btn"
                    onClick={renderProps.onClick}
                  >
                    Se connecter avec Apple id
                  </button>
                )}
              />

              <button className="login-with-google-btn" onClick={() => login()}>
                Se connecter avec compte Gmail{" "}
              </button>
              <FacebookLogin
                buttonStyle={{ padding: "6px" }}
                language="fr_FR"
                appId="1271357420589299"  // we need to get this from facebook developer console by setting the app.
                onSuccess={(response) => {
                  console.log('Login Success!', response);
                  handleFacebookCallback(response)
                }}
                style={{
                  backgroundColor: '#4267b2',
                  color: '#fff',
                  fontSize: '16px',
                  padding: '12px 24px',
                  border: 'none',
                  borderRadius: '20px',
                }}
                onFail={(error) => {
                  console.log('Login Failed!', error);
                }}
                onProfileSuccess={(response) => {
                  console.log('Get Profile Success!', response);
                }}
              />

            </div>
            <div className="inscription">
              Vous n’avez pas encore de compte ?<br />
              <Link to="/register">S’inscrire</Link>
            </div>
            <a href="#" className="passer-connexion">Passer la connexion</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
