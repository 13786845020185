class ApiService {
    static base_url = process.env.REACT_APP_BACKEND_URL + '/api';

    // Helper method to build query parameters
    static buildParams(params) {
        return params ? new URLSearchParams(params).toString() : '';
    }

    // GET all resources with optional query parameters
    static async index(params, axiosInstance, isPublic = false) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${this.base_url}${isPublic ? "/public" : ""}?${queryString}`);
        return response.data;
    }

    // GET a resource by ID with optional query parameters
    static async get(id, params, axiosInstance, isPublic = false) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${this.base_url}${isPublic ? "/public" : ""}/${id}?${queryString}`);
        return response.data;
    }

    // POST a new resource
    static async store(data, axiosInstance, params = null) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.post(`${this.base_url}?${queryString}`, data);
        return response.data;
    }

    // PUT (update) an existing resource
    static async update(id, data, axiosInstance) {
        const response = await axiosInstance.put(`${this.base_url}/${id}`, data);
        return response.data;
    }

    // DELETE a resource by ID
    static async delete(id, axiosInstance) {
        const response = await axiosInstance.delete(`${this.base_url}/${id}`);
        return response.data;
    }

}

export default ApiService;