import React from "react";
import Valide from '../assets/images/valide.svg';
import { Link, redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "./Header";

const PhoneConfirmation = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header />

            <p className="phone-confirmation">
                <h2 className="verification">
                    Vérification du numéro de téléphone<br />
                </h2>
            </p>
            <div className="content-wrap">
                <div className="list-item-with-image">
                    <div className="title">
                        <img
                            className="https-app"
                            alt="Https app"
                            src={Valide}
                        />
                        <div className="text-wrapper"> Le numéro de téléphone est valider.</div>
                    </div>
                </div>

                <div className="buttons">
                    <button className="btn" onClick={() => navigate('/')}>
                        Continuer
                    </button>
                </div>
            </div>
        </>
    );
};


export default PhoneConfirmation;