import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import ProductsService from '../services/product.service';
import PromotionService from '../services/promotion.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

const Package = () => {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [showAddressField, setShowAddressField] = useState(false);
    const [products, setProducts] = useState([]);
    const [packageDetails, setPackageDetails] = useState({
        productId: '', // Selected product ID
        price: '', // Selected product price
        totalUsage: 2, // Default treatments count
        validity: '1 year',
        deliveryAddress: '',
        client_id: auth?.user?.id,
    });

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'productId') {
            const selectedProduct = products.find((product) => product.id.toString() === value);

            setPackageDetails((prevDetails) => ({
                ...prevDetails,
                productId: value,
                price: selectedProduct ? selectedProduct.price : '',
            }));
        } else {
            setPackageDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await ProductsService.index({ with: 'category', is_active: 1 }, axiosPrivate);
            setProducts(response);
        } catch (error) {

        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Generate the promo code dynamically
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let promoCode = '';
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            promoCode += characters.charAt(randomIndex);
        }

        const creationDate = new Date();
        const validityPeriod = packageDetails.validity === '1 year' ? 1 : parseInt(packageDetails.validity, 10);
        const validityDate = new Date(creationDate.setFullYear(creationDate.getFullYear() + validityPeriod));

        const payload = {
            name: 'Package Purchase',
            code: promoCode,
            date_creation: new Date().toISOString().split('T')[0],
            date_validity: validityDate.toISOString().split('T')[0],
            type: 'FLATT_OFF',
            amount: packageDetails.price,
            assigned_to: 'All',
            total_usage: packageDetails.totalUsage,
            usage_per_customer: packageDetails.totalUsage,
            notifyClients: false,
            is_active: 1,
            client_id: packageDetails.client_id,
            product_id: packageDetails.productId,
        };

        try {
            await PromotionService.store(payload, axiosPrivate);
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>Acheter un Package</h2>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="product">Acheter un Package de</label>
                        <select
                            id="product"
                            name="productId"
                            className="form-control"
                            value={packageDetails.productId}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled>
                                Sélectionnez un produit
                            </option>
                            {products.length > 0 ? (
                                products.map((product) => (
                                    <option key={product.id} value={product.id}>
                                        {product.category?.title} / {product.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>
                                    Aucun produit disponible
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="price">Prix du produit</label>
                        <input
                            type="text"
                            id="price"
                            name="price"
                            className="form-control"
                            value={packageDetails.price}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="totalUsage">Nombre de traitements</label>
                        <select
                            id="totalUsage"
                            name="totalUsage"
                            className="form-control"
                            value={packageDetails.totalUsage}
                            onChange={handleInputChange}
                        >
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="6">6</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="validity">Durée</label>
                        <select
                            id="validity"
                            name="validity"
                            className="form-control"
                            value={packageDetails.validity}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="1 year">1 an</option>
                            <option value="2 years">2 ans</option>
                        </select>
                    </div>
                    <div
                        className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : '' }}
                    >
                        <div
                            className="text-wrapper"
                            style={{ color: showAddressField ? '#393D44' : '' }}
                        >
                            Physical gift card
                        </div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>

                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="deliveryAddress">Adresse de livraison</label>
                            <input
                                type="text"
                                id="deliveryAddress"
                                name="deliveryAddress"
                                className="form-control"
                                placeholder="Adresse"
                                value={packageDetails.deliveryAddress}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )}

                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>
        </>
    );
};

export default Package;
