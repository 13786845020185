import ApiService from "./api.service";
export default class UserService extends ApiService {
    static base_url = `${super.base_url}/users`;

    // GET a resource by ID with optional query parameters
    static async currentUser(params, axiosInstance) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${super.base_url}/auth/me?${queryString}`);
        return response.data;
    }

    static async indexByRole(params, role, axiosInstance, isPublic = false) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${this.base_url}/role${isPublic ? "/public" : ""}/${role}?${queryString}`);
        return response.data;
    }

    static async sendOTP(axiosInstance) {
        const response = await axiosInstance.post(`${super.base_url}/auth/otp/send`);
        return response.data;
    }

    static async verifyOTP(body, axiosInstance) {
        const response = await axiosInstance.post(`${super.base_url}/auth/otp/verify`, body);
        return response.data;
    }
}
