import React from 'react';


const DateTimeSelectorFavPro = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  modalIsOpen2,
  handleMonthChange,
  handleDayChange,
  handleDayClick,
  handleHourClick,
  daysToShow,
  hours,
  totalPrice,
  scrollLeft,
  scrollRight,
  openModal,
  getMonthName,
  getDayName,
  unavailableDates,
  availableHours,
  buffertime,
  totalDuration,
  traveldistance, //travelDistance: xx hours xx mins or xx mins
}) => {
  const parseTravelDistance = (travelDistance) => {
    if (typeof travelDistance !== "string") {
      return 0; // Default to 0 if travelDistance is not a string
    }
  
    const hoursRegex = /(\d+)\s*hours?/;
    const minutesRegex = /(\d+)\s*mins?/;
  
    const hoursMatch = travelDistance.match(hoursRegex);
    const minutesMatch = travelDistance.match(minutesRegex);
  
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) * 100 : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
  
    return hours + minutes;
  };
  
  
  const isHourAvailable = (hour) => {
    // console.log('travelTime',travelTime)

    const travelTime = parseTravelDistance(traveldistance);
    // console.log('travelTime',travelTime)
    return availableHours.some(({ start_time, end_time }) => {
      const [hourHours, hourMinutes] = hour.split(':').map(Number);
      const hourValue = hourHours * 100 + hourMinutes;

      const [startHours, startMinutes] = start_time.split(':').map(Number);
      // const startValue = startHours * 100 + startMinutes + Number(buffertime.bufferBefore);
      const startValue = startHours * 100 + startMinutes + Number(buffertime.bufferBefore) + travelTime;
      // console.log('startValue',startValue)

      const [endHours, endMinutes] = end_time.split(':').map(Number);
      const endValue = endHours * 100 + endMinutes - Number(buffertime.bufferAfter) - 40 - totalDuration - 40;
      return hourValue >= startValue && hourValue <= endValue ;
    });
  };
  return (
    <div>
      <div className={`form-group group-mois ${modalIsOpen2 && "group-mois-padding"}`}>
        {/* {!modalIsOpen2 &&
          <label>Mois</label>
        } */}
        <div className="btn-group" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={() => handleMonthChange(-1)}>
            &lt;
          </button>
          <span className="text-month"> {getMonthName((selectedDate || new Date()))}</span>
          <button type="button" className="btn-slide btn-next btn-month" onClick={() => handleMonthChange(1)}>
            &gt;
          </button>
        </div>
      </div>
      <div className="form-group group-jour modal-therapist">
        {/* {!modalIsOpen2 &&
          <label>Jour</label>} */}
        <div className="btn-group" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={() => handleDayChange(-1)}>
            &lt;
          </button>
          <div className="row-date">
          {daysToShow.map((date) => {
            const isUnavailable = unavailableDates.includes(date.toDateString());
            return (
              <button
                key={date.getDate()}
                type="button"
                className={`btn-day ${!isUnavailable ? 'disabled' : ''} ${date?.toDateString() === selectedDate?.toDateString() ? 'selected' : ''
                  }`}
                onClick={() => isUnavailable && handleDayClick(date)}
                disabled={!isUnavailable}
              >
                {`${getDayName(date)}`} <br /> <span>{`${date.getDate()}`}</span>
                <br /> {`${!isUnavailable ? 'epuisé' : ''}`}
              </button>
            );
          })}
          </div>
          <button type="button" className="btn-slide btn-next btn-month" onClick={() => handleDayChange(1)}>
            &gt;
          </button>
        </div>
      </div>
      <div className="form-group group-heure modal-therapist">
        {/* <label>{!modalIsOpen2 ? 'Heure' : 'Durée'}</label> */}
        <div className="btn-group" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={scrollLeft}>
            &lt;
          </button>
          <div className="row-day">
          {hours.map((hour) => {
              const isUnavailable = !isHourAvailable(hour);
              return (
                <button
                  type="button"
                  key={hour}
                  className={`btn-hour ${isUnavailable ? 'disabled' : ''} ${selectedTime === hour ? 'selected' : 'btn-outline-primary'
                    }`}
                  onClick={() => !isUnavailable && handleHourClick(hour)}
                  disabled={isUnavailable}
                >
                  {hour} <br />
                  <span>{totalPrice}&nbsp;chf</span>
                  <br /> {`${isUnavailable ? 'epuisé' : ' '}`}
                </button>
              );
            })}
          </div>
          <button type="button" className="btn-slide btn-next btn-month" onClick={scrollRight}>
            &gt;
          </button>
        </div>
        {!modalIsOpen2 && window.location.pathname !== "/flypro-booking" &&
          <>
            <a href="#0" className="filter-user" onClick={openModal}>
              Filtrer vos préférences
            </a>
            <p>Veuillez sélectionnez le thérapeute ou cliquez sur le bouton aucune préférence</p>
          </>
        }
      </div>
    </div>
  );
};

export default DateTimeSelectorFavPro;
