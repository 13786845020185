import React, { useEffect, useState } from 'react';
import Header from './Header';
import coins from '../assets/images/coins.svg';
import GiftConfigurationService from '../services/gift_configuration.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';


const Gift = () => {
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate()


  useEffect(() => {
    const fetchGifts = async () => {
      try {
        const today = new Date().toISOString();
        const response = await GiftConfigurationService.index({ valid_until: `gte:${today}`, is_active: 1, with: 'media' }, axiosPrivate);
        // console.log("gifts", response)
        setGifts(response);
      } catch (err) {
        setError('Failed to fetch gifts');
      } finally {
        setLoading(false);
      }
    };

    fetchGifts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const handleGiftClick = (gift) => {
    console.log("Navigating with gift data:", gift);
    navigate('/buy-voucher', { state: { selectedGift: gift } });
  };

  return (
    <>
      <Header />
      <div className="container gift-options-container">
        <h2>Catégorie des cadeaux</h2>
        
        {gifts && gifts.length > 0 ? (
          gifts.map((gift) => (
            <div className="gift-option" key={gift.id} onClick={() => handleGiftClick(gift)}>
              <div className="icon">
                <img src={gift.media[0]?.original_url || coins} alt="Icon" />
              </div>
              <div className="content">
                <h3>{gift.name}</h3>
                <h4>{gift.description}</h4>
                <a href={`/gift-details/${gift.id}`}>More info &rsaquo;</a>
              </div>
            </div>
          ))
        ) : (
          <p>No gifts available</p>
        )}
      </div>
    </>
  );
}

export default Gift;
