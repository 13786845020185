
import { axiosPrivate } from "../api/axios";
import AuthService from "../services/auth.service";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        if (auth?.accessToken) {
            try {
                await AuthService.logout(axiosPrivate)
            } catch (e) { }
        }
        setAuth({});
        localStorage.removeItem("accessToken");
        navigate("/login");
    }


    return logout;
}

export default useLogout