import React, { useState, useRef, useEffect } from "react";
import ReviewItem from "./ReviewItem";

const ReviewList = ({ currentTherapist }) => {
  const parsedAvis = currentTherapist?.ratings || [];
  const positiveReviews = parsedAvis.filter((review) => review.rating >= 0);
  const sortedReviews = positiveReviews.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const [visibleCount, setVisibleCount] = useState(3);
  const reviewsRef = useRef(null);
  const isDragging = useRef(false);
  const startY = useRef(0);
  const scrollTop = useRef(0);

  useEffect(() => {
    const container = reviewsRef.current;
    if (!container) return;

    const handleMouseDown = (e) => {
      e.preventDefault();
      isDragging.current = true;
      startY.current = e.clientY;
      scrollTop.current = container.scrollTop;
      container.style.cursor = "grabbing";
      container.style.userSelect = "none";

      document.body.style.userSelect = "none";
    };

    const handleMouseMove = (e) => {
      if (!isDragging.current) return;

      const dy = e.clientY - startY.current;
      container.scrollTop = scrollTop.current - dy;

      if (container.scrollHeight - container.scrollTop <= container.clientHeight + 10) {
        setVisibleCount((prev) => prev + 3);
      }
    };

    const handleMouseUp = () => {
      isDragging.current = false;
      container.style.cursor = "grab";
      container.style.userSelect = "auto";
      document.body.style.userSelect = "auto";
    };

    container.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      container.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);


  return (
    <div className="review-list">
      <div className="modal-therapist">
        <h3 className="info-title">Avis ({sortedReviews.length} avis)</h3>
        <div
          className="reviews"
          ref={reviewsRef}
          style={{
            overflowY: "auto",
            maxHeight: "400px",
            cursor: "grab",
            userSelect: "none",
          }}
        >
          {sortedReviews.slice(0, visibleCount).map((review, index) => (
            <ReviewItem key={index} review={review} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewList;
