import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import MenuHeader from "./MenuHeader";
import BookingService from "../services/booking.service";
import RatingService from "../services/rating.service";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useLocation } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { addressFormat } from "../shared/utils/string";
import { styled } from "@mui/material/styles";
import star2 from '../assets/images/star2.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    height: 'auto',
    width: '400px',
    borderRadius: '10px',
    padding: '20px',
  },
};

const CustomRating = styled(Rating)({
  "& .MuiRating-iconEmpty": {
    opacity: 0.8,
    color: '#faef9b',
  },
  "& .MuiRating-icon": {
    width: "30px",
    height: "30px",
    margin: "0 10px",
  },
});

function Commande() {
  const [bookings, setBookings] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [rating, setRating] = useState(5); // Default rating to 5
  const [comment, setComment] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const addMinutesToTime = (time, duration) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + duration);

    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  };

  const fetchBookings = async () => {
    try {
      const response = await BookingService.index({ with: 'address,therapists.media,client,product.category,ratings', client_id: `${auth?.user ? auth.user.id : -1}`, sort: '-created_at', id: id ? `in:${id}` : "", }, axiosPrivate);
      console.log({ response })
      setBookings(response)
    } catch (error) {

    }
  }
  const formatSelectedDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(date));
  };

  useEffect(() => {
    fetchBookings();
  }, [])

  const openModal = (therapist, bookingId) => {
    setSelectedTherapist(therapist);
    setSelectedBookingId(bookingId);
    setRating(5); // Default to 5 stars
    setComment("");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setRating(5); // Reset to default 5 stars
    setComment("");
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();

    const formData = {
      rating,
      comment,
      therapist_id: selectedTherapist?.id,
      booking_id: selectedBookingId,
    };

    try {
      await RatingService.store(formData, axiosPrivate);

      setModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {

    }
  };

  return (
    <>

      <MenuHeader />
      <div className="content-wrapper">
        <div className="content-wrapper duo-pay">

          {bookings.map((booking) => {
            // console.log(booking.status, booking.id)
            if (booking.isDuo && booking.therapists.length > 1) {

              return (<div className='duo-card'>
                {booking.therapists.map((therapist) => (

                  <div key={`${booking.id}-${therapist.id}`} className={`column duo-col ${booking.status === "PENDING_ACKNOWLEDGEMENT" ? "card-disabled" : ""}`}>
                    <div className="summary-box">
                      {therapist && (
                        <div className="name">
                          <div className="image" style={{ backgroundImage: `url(${therapist.media.length ? therapist.media[0].original_url : "assets/images/icon-pro.svg"})` }}></div>
                          <div className="title">
                            <div className="text-wrapper-2">{therapist?.firstname}</div>
                            <div className="rating">
                              <div className="stars">
                                <div className="text-wrapper-3">
                                  <img className="https-app" src={star2} alt="Rating" />
                                </div>
                              </div>
                              <div className="text-wrapper-4">{therapist?.extra?.rating ? therapist.extra.rating.toFixed(1) : "5"}/5</div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="div-2">
                        <div className="text-wrapper-5">{booking?.product?.category?.title}</div>
                      </div>

                      <div className="div-2">
                        <p className="text-wrapper-5">
                          {new Intl.DateTimeFormat("fr-FR", { month: "short", day: "numeric" }).format(new Date(booking?.start_before))}{" "}
                          {booking?.start_before.split("T")[1].split(":")[0]}:
                          {booking?.start_before.split("T")[1]?.split(":")[1]}{" - "}
                          {addMinutesToTime(booking?.start_before.split("T")[1].slice(0, 5), booking?.duration)}
                        </p>
                        <div className="text-wrapper-6">{booking?.product?.price} CHF</div>
                      </div>

                      <div className="div-2">
                        <p className="text-wrapper-5">
                          {booking?.address?.street} {booking?.address?.streetnumber}
                          <br />
                          {booking?.address?.zip_code} {booking?.address?.state}
                          <br />
                        </p>
                      </div>
                      {!booking?.ratings[0]?.rating && (() => {
                        const isDisabled = new Date(booking.start_before).getTime() > Date.now();

                        return (
                          <>
                            {isDisabled}

                            <button
                              style={{ display: isDisabled || booking.status === "COMPLETED" ? "none" : "inline-block" }}
                              className="review-button"
                              onClick={() => openModal(therapist, booking.id)}
                            >
                              <img
                                src="/assets/images/trash-alt.png"
                                alt="Evaluate Flypro"
                                className="review-icon"
                              />
                              Évaluez votre flypro
                            </button>
                          </>
                        );
                      })()}



                    </div>
                  </div>
                ))}
              </div>
              );

            } else {
              // If not a duo booking, handle single therapist normally
              const therapist = booking.therapists[0];
              return (
                <div key={`${booking.id}-${therapist?.id}`} className={`column ${booking.status === "PENDING_ACKNOWLEDGEMENT" ? "card-disabled" : ""}`}>
                  <div className="summary-box">
                    {therapist && (
                      <div className="name">
                        <div className="image" style={{ backgroundImage: `url(${therapist.media.length ? therapist.media[0].original_url : "assets/images/icon-pro.svg"})` }}></div>
                        <div className="title">
                          <div className="text-wrapper-2">{therapist?.firstname}</div>
                          <div className="rating">
                            <div className="stars">
                              <div className="text-wrapper-3">
                                <img className="https-app" src={star2} alt="Rating" />
                              </div>
                            </div>
                            <div className="text-wrapper-4">{therapist?.extra?.rating ? therapist.extra.rating.toFixed(1) : "5"}/5</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="div-2">
                      <div className="text-wrapper-5">{booking?.product?.category?.title}</div>
                    </div>

                    <div className="div-2">
                      <p className="text-wrapper-5">
                        {new Intl.DateTimeFormat("fr-FR", { month: "short", day: "numeric" }).format(new Date(booking?.start_before))}{" "}
                        {booking?.start_before.split("T")[1].split(":")[0]}:
                        {booking?.start_before.split("T")[1]?.split(":")[1]}{" - "}
                        {addMinutesToTime(booking?.start_before.split("T")[1].slice(0, 5), booking?.duration)}
                      </p>
                      <div className="text-wrapper-6">{booking?.product?.price} CHF</div>
                    </div>

                    <div className="div-2">
                      <p className="text-wrapper-5">
                        {booking?.address?.street} {booking?.address?.streetnumber}
                        <br />
                        {booking?.address?.zip_code} {booking?.address?.state}
                        <br />
                      </p>
                    </div>
                    {!booking?.ratings[0]?.rating && (() => {
                      const isDisabled = new Date(booking.start_before).getTime() > Date.now();

                      return (
                        <>
                          {isDisabled}

                          <button
                            style={{ display: isDisabled || booking.status === "COMPLETED" ? "none" : "inline-block" }}
                            className="review-button"
                            onClick={() => openModal(therapist, booking.id)}
                          >
                            <img
                              src="/assets/images/trash-alt.png"
                              alt="Evaluate Flypro"
                              className="review-icon"
                            />
                            Évaluez votre flypro
                          </button>
                        </>
                      );
                    })()}

                  </div>
                </div>
              );
            }
          })}

        </div>
      </div>






      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} className="review-modal">
        <button className="close-button-evaluer" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <div className="evaluation-pro">
          Évaluez votre flypro</div>

        <div className="therapist-review-details">
          <img src={!selectedTherapist?.media?.length ? "assets/images/icon-pro.svg" : selectedTherapist?.media[0].original_url} alt="Therapist" className="therapist-image" />
          <span className="therapist-name">{selectedTherapist?.firstname}<br /><img src={star2} /> {selectedTherapist?.extra?.rating ? selectedTherapist.extra.rating.toFixed(1) : "5"}/5</span>
        </div>
        <form onSubmit={handleSubmitReview}>
          <label>Note d’évaluation</label>
          <div className="formField margin-top">
            <CustomRating
              name="custom-rating"
              precision={0.5}
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
              size="large"
              icon={<img src="/assets/images/star-sharp.png" alt="filled star" />}
            />
          </div>

          <div className="comment-section">
            <label>Commentaire</label>
            <textarea placeholder="Écrivez votre commentaire (facultatif)" value={comment} className="textformarea margin-top" onChange={(e) => setComment(e.target.value)} />
          </div>

          <button className="btn-submit" type="submit">
            Valider
          </button>
        </form>
      </Modal>

      <Modal isOpen={successModalIsOpen} onRequestClose={() => setSuccessModalIsOpen(false)} style={customStyles} className="review-modal">
        <button className="close-button-evaluer" onClick={() => setSuccessModalIsOpen(false)}>
          <span className="close-icon">✖</span>
        </button>

        <div className="success-message-container">
          <span className="success-check">✔</span>
          <span className="success-message">Merci pour votre évaluation !</span>
        </div>
      </Modal>
    </>
  );
}

export default Commande;
