import React from 'react';

const Informations = ({ currentTherapist }) => {

  let parsedTags = {};
  try {
    parsedTags = currentTherapist?.tags ? JSON.parse(currentTherapist.tags) : {};
  } catch (error) {

  }

  return (
    <div className="modal-therapist">
      <h3 className='info-title'>Informations</h3>
      <div className="informations">

        <h4>Bio</h4>
        <p>{parsedTags?.bio}</p>

      </div>
      {/* <div className="informations">

        <h4>Soins</h4>
        <ul className='list-soin'>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
        </ul>

      </div> */}
      <div className="informations">

        <h4>Expérience</h4>
        <ul className='list-soin'>
          {parsedTags?.experience && Array.isArray(parsedTags.experience) ? (
            parsedTags.experience.map((experience, index) => <li key={index}>{experience}</li>)
          ) : (
            <li>Aucune expérience renseignée</li>
          )}
        </ul>

      </div>
      <div className="informations">

        <h4>Langue</h4>
        <p>
          {parsedTags?.language && Array.isArray(parsedTags.language) ? (
            parsedTags.language.map((language, index) => (
              <span key={index}>
                {language}
                {index < parsedTags.language.length - 1 ? ", " : ""}
              </span>
            ))
          ) : (
            "Aucune langue renseignée"
          )}
        </p>

      </div>
    </div>
  );
};

export default Informations;
