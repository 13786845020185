// ChooseLocation.js
import React from 'react';
import Header from './Header'

const ValidationNumero = () => {

  return (
    <>
    <Header />

    <div className="container container-contact">
      <h2>Vérification du numéro de téléphone</h2>
      <div className="valid-bloc">
        <div className="icon"></div>
        <p>Le numéro de téléphone est validé.</p>
      </div>
    </div>
    </>
  );
}

export default ValidationNumero;
