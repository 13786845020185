import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { setupAuthorizationInterceptor } from "../interceptors/authorization.interceptor";
import { setupEncryptorInterceptor } from "../interceptors/encryptor.interceptor";
import { setupCsrfInterceptor } from "../interceptors/csrf.interceptor";
import { setupLoadingInterceptor } from "../interceptors/loading.interceptor";
import { setupNotificationInterceptor } from "../interceptors/notification.interceptor";
import { useLoading } from '../context/LoadingProvider';
import { useNotifications } from '../context/NotificationProvider';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const loader = useLoading();
    const notify = useNotifications();

    useEffect(() => {
        const cleanUpAuthorization = setupAuthorizationInterceptor(axiosPrivate, refresh, auth);
        const cleanUpEncryptor = setupEncryptorInterceptor(axiosPrivate);
        const cleanUpCsrf = setupCsrfInterceptor(axiosPrivate);
        const loadingInterceptor = setupLoadingInterceptor(axiosPrivate, loader);
        const notificationInterceptor = setupNotificationInterceptor(axiosPrivate, notify);
        return () => {
            // Cleanup interceptors
            cleanUpAuthorization();
            cleanUpEncryptor();
            cleanUpCsrf();
            loadingInterceptor();
            notificationInterceptor();
        };
    }, []);

    return axiosPrivate;
};

export default useAxiosPrivate;
