import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import ZoneService from '../services/zone.service';
import { useNotifications } from '../context/NotificationProvider';



const libraries = ['places'];

const Voucher = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
        libraries,
    });
    const navigate = useNavigate();
    const { auth } = useAuth();
    const location = useLocation();
    const selectedGift = location.state?.selectedGift;
    const [address, setAddress] = useState({
        street: '',
        city: '',
        zip_code: '',
        street_number: '',
        state: '',
        state_code: '',
        country: '',
        country_code: '',
        lat: null,
        lng: null,
    });
    const axiosPrivate = useAxiosPrivate();
    const notify = useNotifications();
    const [showAddressField, setShowAddressField] = useState(false);
    const [voucherDetails, setVoucherDetails] = useState({
        client_id: auth?.user?.id,
        from_name: '',
        recipient_name: '',
        recipient_email: '',
        message: '',
        configuration: '',
    });

    console.log('selectedGift', selectedGift)

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
        setAddress({
            street: '',
            city: '',
            zip_code: '',
            street_number: '',
            state: '',
            state_code: '',
            country: '',
            country_code: '',
            lat: null,
            lng: null,
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "configuration") {
            const selectedConfig = selectedGift.configuration.find(
                (config) => config.title === value
            );

            if (selectedConfig) {
                setVoucherDetails((prevDetails) => ({
                    ...prevDetails,
                    configuration: selectedConfig,
                }));
            }
        } else {
            setVoucherDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };

    const checkZipCodeInRange = async (zip_code) => {
        try {
            const zoneResponse = await ZoneService.index(null, axiosPrivate, auth?.accessToken ? false : true);

            if (zoneResponse) {
                const zipNumeric = parseInt(zip_code, 10);
                let isInRange = false;

                for (const zone of zoneResponse) {

                    if (isZipCodeInRange(zipNumeric, zone.zip_code)) {
                        isInRange = true;
                        break;
                    }
                }
                return isInRange;
            } else {
                console.error("Erreur lors de la récupération des zones.");
            }
        } catch (error) {
            console.error("Erreur lors de l'appel de ZoneService.index :", error);
        }
    };

    function isZipCodeInRange(zipCode, range) {

        const [start, end] = range.split('-').map(Number);
        const zip = parseInt(zipCode, 10);

        return zip >= start && zip <= end;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (address.zip_code) {
            console.log('add zip', address.zip_code)
            const isGiftZone = await checkZipCodeInRange(address.zip_code);
            if (!isGiftZone) {
                notify.warn('Make sure the recipient is in the serviced zones');
                // return false;
            }
            if (!address.zip_code) {
                notify.warn('Veuillez saisir votre code postal');
            }
        }
        console.log('state gift submit', { state: { selectedGift, address: address, giftDetails: voucherDetails } })
        navigate('/paymentgift', { state: { selectedGift, address: address, giftDetails: voucherDetails } });

    }

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps</div>;

    const handlePlaceSelect = (place) => {
        const addressComponents = place.address_components;
        const newAddress = {
            street: '',
            city: '',
            zip_code: '',
            street_number: '',
            state: '',
            state_code: '',
            country: '',
            country_code: '',
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        console.log("places", addressComponents)
        for (const component of addressComponents) {
            const componentType = component.types[0];
            switch (componentType) {
                case 'street_number':
                    newAddress.street = `${component.long_name} ${newAddress.street}`;
                    break;
                case 'route':
                    newAddress.street = `${newAddress.street} ${component.long_name}`;
                    break;
                case 'locality':
                    newAddress.city = component.long_name;
                    break;
                case 'postal_code':
                    newAddress.zip_code = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    newAddress.state = component.long_name;
                    newAddress.state_code = component.short_name;
                    break;
                case 'country':
                    newAddress.country = component.long_name;
                    newAddress.country_code = component.short_name;
                    break;
                /*  case 'street_number':
                   newAddress.street_number = component.long_name;
                   break; */
                default:
                    break;
            }
        }

        setAddress(newAddress);
    };

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>{selectedGift.name}</h2>
                <h3>{selectedGift.description}</h3>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="configuration">Gift Offers</label>
                        <select
                            id="configuration"
                            name="configuration"
                            className="form-control"
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select a Gift Offer</option>
                            {selectedGift.configuration.map((config, index) => (
                                <option key={index} value={config.title}>
                                    {`${config.title} - ${config.price} CHF`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="validity">Validité</label>
                        <input
                            id="validity"
                            name="validity"
                            className="form-control"
                            value="2 ans"
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="from_name">Gift From</label>
                        <input
                            type="text"
                            id="from_name"
                            name="from_name"
                            className="form-control"
                            placeholder="Add Name"
                            value={voucherDetails.from_name}
                            required
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="recipient_name">Recipient Details</label>
                        <input
                            type="text"
                            id="recipient_name"
                            name="recipient_name"
                            className="form-control"
                            placeholder="Add Recipient Name"
                            value={voucherDetails.recipient_name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <br />
                        <input
                            type="text"
                            id="recipient_email"
                            name="recipient_email"
                            className="form-control"
                            placeholder="Add Recipient Email"
                            value={voucherDetails.recipient_email}
                            required
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            type="text"
                            id="message"
                            name="message"
                            className="form-control"
                            placeholder="Add Message"
                            value={voucherDetails.message}
                            onChange={handleInputChange}
                            style={{ height: '100px' }}
                        />
                    </div>
                    <div
                        className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : '' }}
                    >
                        <div
                            className="text-wrapper"
                            style={{ color: showAddressField ? '#393D44' : '' }}
                        >
                            Carte cadeau physique
                        </div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="street">Saisissez une adresse</label>
                            <Autocomplete
                                apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
                                onPlaceSelected={handlePlaceSelect}
                                options={{
                                    types: ['address'],
                                    componentRestrictions: { country: 'CH' },
                                }}
                                style={{
                                    width: '100%',
                                    padding: '0 15px',
                                    height: '60px',
                                    border: '0',
                                    borderRadius: '20px',
                                    boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)',
                                }}
                            />
                        </div>
                    )}
                    {address.lat && address.lng && (
                        <>
                            <div className="form-group">
                                <input
                                    type="hidden"
                                    id="street"
                                    className="form-control"
                                    value={address.street}
                                    onChange={(e) => setAddress({ ...address, street: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="hidden"
                                    id="state"
                                    className="form-control"
                                    value={address.state}
                                    onChange={(e) => setAddress({ ...address, state: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                {(!address.zip_code || address.zip_code.length < 8) && (
                                    <>
                                        <label htmlFor="postalCode">Code postal</label>
                                        <input
                                            type="text"
                                            id="postalCode"
                                            className="form-control"
                                            value={address.zip_code}
                                            onChange={(e) => setAddress({ ...address, zip_code: e.target.value })}
                                            required
                                        />
                                    </>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="hidden"
                                    id="city"
                                    className="form-control"
                                    value={address.city}
                                    onChange={(e) => setAddress({ ...address, city: e.target.value })}
                                />
                            </div>
                        </>
                    )}
                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>
        </>
    );
};

export default Voucher;
