// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import UserService from '../services/user.service';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from '../hooks/useAuth';
import { PhoneInput } from 'react-international-phone';

const VerificationNumero = () => {

  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const [formData, setFormData] = useState({
    otp: '',

  });
  const [formDataPhone, setFormDataPhone] = useState({
    phone: auth.user?.phone ?? '',
  });

  console.log('auth', auth)
  console.log('auth num', auth.user.phone)

  const navigate = useNavigate();
  useEffect(() => {
    sendOTP();
  }, [auth]);

  const sendOTP = async () => {
    try {
      if (auth.user?.phone) {
        await UserService.sendOTP(axiosPrivate);
        disableResend();
      }
    } catch (e) {

    }

  }

  const updateUserPhone = async (event) => {
    event.preventDefault();
    try {
      const responseup = await UserService.update(auth.user.id, { phone: formDataPhone.phone }, axiosPrivate);
      auth.user.phone = formDataPhone.phone;
      setAuth(auth);
      sendOTP();
      console.log('responseupfin', responseup.data.phone)
    } catch (e) {

    }

  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('formData.phone', formData.phone);
    try {
      const response = await UserService.verifyOTP(formData, axiosPrivate);
      console.log('response', response);

      await UserService.verifyOTP(formData, axiosPrivate);
      navigate('/phone-confirmation');
    } catch (e) {

    }
  }

  const handlePhoneChange = (value) => {
    setFormDataPhone((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  const handleChange = (event) => {

    console.log('event.target.value', event.target.value)
    setFormData({ otp: event.target.value })

  }
  const disableResend = (time = 10000) => {
    const resendBtn = document.querySelector('#resend');

    resendBtn.disabled = true;
    resendBtn.innerHTML = `Resend (${time / 1000}s)`;
    if (time === 0) {
      resendBtn.disabled = false;
      resendBtn.innerHTML = `Resend`;
    }
    else {
      setTimeout(() => {
        disableResend(time - 1000);
      }, 1000);
    }
  }
  return (
    <>
      <Header />

      <div className="container container-contact">
        <h2>Vérification du numéro de téléphone</h2>

        {!auth.user?.phone && (
          <>
            <p>Veuillez entrer votre numéro de téléphone pour continuer</p>
            <form onSubmit={updateUserPhone}>
              <div className="form-text">
                <label>Numéro de téléphone portable:</label>
                <PhoneInput
                  defaultCountry="ch"
                  type="tel"
                  name="phone"
                  value={formDataPhone.phone}
                  disableCountryGuess="true"
                  //hideDropdown="true"
                  forceDialCode="true"
                  //onChange={handlePhoneChange}
                  onChange={(phone) => handlePhoneChange(phone)}
                  required
                />
              </div>
              <div className="form-submit">
                <input type="submit" value="Mettre A jour" />
              </div>
            </form>
          </>)}

        {auth.user?.phone && (<>
          <p>Afin de confirmer votre numéro de mobile un code vous à été envoyé.<br />Veuillez saisir le code reçu par SMS</p>
          <form onSubmit={handleSubmit}>
            <div className="flex-row gap-10 align-center">
              <div className="form-text flex-1-auto no-margin">
                <label>Code</label>
                <input type="text" value={formData.otp} onChange={handleChange} name="otp" id="otp" />
              </div>
              <button type='button' onClick={sendOTP} className="btn no-margin" id='resend'>Resend</button>
            </div>
            <div className="form-submit">
              <input type="submit" value="Vérifier" />
            </div>
          </form></>)}
      </div>
    </>
  );
}

export default VerificationNumero;
