import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function HotelDetails({ hotel, center }) {
  const navigate = useNavigate();

  const handleChoose = () => {
    navigate('/hotel-choose-service', { state: { hotel: hotel, hotelId: hotel.id, duo_capability: hotel.duo_capability, hotel_address: hotel.address_id } });
  };

  return (
    <>
      <div className={`hotel-detail-card ${center ? 'center' : ''}`}>
        <img className='hotel-img' src={hotel.media?.length ? hotel.media[0].original_url : 'assets/images/hotel.png'} alt='hotel_image' />
        {hotel.duo_capability && (<div className='badge-duo'><img src='assets/images/icon-checked.svg' alt='star icon' />DUO</div>)}
        <div className='hotel-name'>{hotel.name}</div>
        <div className='hotel-rating'><img src="/assets/images/icon-stars.svg" alt='star icon' height={13.5} width={13.5} /> 4.9/5</div>
        <div className='hotel-distance'>2km from the city center</div>
        <div className='hotel-description'><b>Description: </b>{hotel.description}</div>
        <button className='btn-primary' onClick={handleChoose}>Choisir</button>
      </div>
    </>
  );
}

export default HotelDetails;
