import React, { useEffect } from 'react';
import star2 from '../assets/images/star2.svg';

const HeaderTherapist = ({ currentTherapist, closeModal2 }) => {
  useEffect(() => {
    console.log(currentTherapist.currentTherapist)
  }, [currentTherapist]);
  return (
    <div className="header-therapist">
      <div className='modal-therapist'>
        <div className="card-therapist">
          <img src={currentTherapist?.media.length ? currentTherapist.media[0].original_url : ''} alt={currentTherapist?.firstname} className="profile-picture-choose" />
          <h2 className="name-therapist">{currentTherapist?.firstname}</h2>
        </div>
        <div className="text-wrapper-3"> </div>
        <div className="rating"><img className="https-app" src={star2} /> {currentTherapist?.extra?.rating}/5</div>

        <a className="header-close" onClick={() => closeModal2()}>X</a>
      </div>
    </div>
  );
};

export default HeaderTherapist;
