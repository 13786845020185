import React from "react";
import { useNavigate } from "react-router-dom";
// import "./Header.css";

const Header = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="header">
      <div className="turn-back">
        <div className="frame">
          <div className="div" onClick={handleGoBack} >{"<"} Retour</div>
        </div>
      </div>
      <a href="/" className="logo hlogo">Flyspa</a>
    </div>
  );
};

export default Header;
