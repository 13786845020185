import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, createTheme, IconButton, ThemeProvider } from '@mui/material';
const theme = createTheme({
  palette: {
    dark_yellow: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#FAEF9B",
    },
    dark_white: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#FFFFFF",
    },
    dark: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#393D44"
    }
  },
});
const Nav = ({ page }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isPassed = (currentPage, targetPage) => {
    const order = ["adresse", "service", "therapist", "payment"];
    return order.indexOf(currentPage) > order.indexOf(targetPage);
  };

  const isCurrentOrPassed = (currentPage, targetPage) => {
    const order = ["adresse", "service", "therapist", "payment"];
    return order.indexOf(currentPage) >= order.indexOf(targetPage);
  };

  const goBack = () => {
    const currentState = JSON.parse(sessionStorage.getItem('state') || {});

    switch (location.pathname) {
      case "/choose-service":
        navigate('/choose-location', { state: currentState });
        break;
      case "/choose-location":
        navigate('/', { state: currentState });
        break;
      case "/choose-therapist":
        navigate('/choose-service', { state: currentState });
        break;
      case "/payment":
        navigate('/choose-therapist', { state: currentState });
        break;
      default:
        navigate('/', { state: currentState });
        break;
    }
  };

  const handleNav = (current, target) => {
    if (current === target) {
      return;
    }
    const currentState = JSON.parse(sessionStorage.getItem('state') || {});
    switch (target) {
      case "service":
        navigate('/choose-service', { state: currentState });
        break;
      case "adresse":
        navigate('/choose-location', { state: currentState });
        break;
      case "therapist":
        navigate('/choose-therapist', { state: currentState });
        break;
      case "payment":
        navigate('/payment', { state: currentState });
        break;
      default:
        navigate('/choose-service', { state: currentState });
        break;
    }
  };
  return (
    <div className='menu-nav-container'>
      <div className="menu-nav">
        <ThemeProvider theme={theme}>
          <Button
            variant="text"
            color="dark"
            sx={{ textTransform: 'none', fontSize: '20px', gap: '10px' }}
            onClick={goBack}
          >
            <img src='assets/images/icon-back.svg' /> Retour
          </Button>
        </ThemeProvider>
        <ul>
          {["adresse", "service", "therapist", "payment"].map((step, index) => (
            <li key={index}>
              <ThemeProvider theme={theme}>
                <Button
                  variant={!isCurrentOrPassed(page, step) ? 'outlined' : 'contained'}
                  color={isPassed(page, step) ? 'dark_white' : !isCurrentOrPassed(page, step) ? 'dark' : 'dark_yellow'}
                  sx={{ borderRadius: '50px', gap: '5px', opacity: isPassed(page, step) ? 0.5 : 1, textTransform: 'none' }}
                  disabled={!isCurrentOrPassed(page, step)}
                  onClick={() => handleNav(page, step)}
                >
                  {
                    step === "adresse" ? "Votre adresse" :
                      step === "service" ? "Choisissez votre soin" :
                        step === "therapist" ? "Choisissez votre thérapeute" : "Paiement"}
                  {isCurrentOrPassed(page, step) && !isPassed(page, step) ? (<img src='assets/images/icon-next-yellow.svg' />) : (isPassed(page, step) ? (<img src='assets/images/icon-check.svg' />) : (<></>))}
                </Button>
              </ThemeProvider>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Nav;