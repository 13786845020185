// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import MenuHeader from './MenuHeader';
import { useLocation } from 'react-router-dom';
import AddressService from '../services/address.service';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNotifications } from '../context/NotificationProvider';
import Modal from "react-modal";
import ZoneService from '../services/zone.service';
import UnavailableZoneService from '../services/unavailable_zones.service';
import croix from "../assets/images/close-black.png";
import { endpoints } from '../utils/utils';
import DomainService from '../services/domain.service';

const customStyles = {
  content: {
    width: "33%",
    top: "50%",
    padding: "40px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "450px",
    margin: "auto",
    textAlign: "center",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};


Modal.setAppElement("#root");

const libraries = ['places'];
const ChooseLocation = () => {

  const navigate = useNavigate();
  const notify = useNotifications();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [modalIsOpenService, setIsOpenService] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
    libraries,
  });
  const { auth } = useAuth();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [hotelAddress, setHotelAddress] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState({
    street: '',
    city: '',
    zip_code: '',
    street_number: '',
    state: '',
    state_code: '',
    country: '',
    country_code: '',
    lat: null,
    lng: null,
  });
  const [user, setUser] = useState();

  const [email, setEmail] = useState("");
  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    // const updatedState = { ...JSON.parse(sessionStorage.getItem('state') ?? '{}'), address, phone };
    // sessionStorage.setItem('state', JSON.stringify(updatedState));

    // Prepare the data for submission
    const payload = {
      email: email,
      zip_code: address.zip_code,
      user_id: auth?.user?.id || null,
      zone_id: auth?.user?.id || null
      // domain_id: auth?.user?.id || null,
      // is_zone: true,
      // is_service: false,
    };
    console.log('payload', payload)

    try {
      const response = await UnavailableZoneService.store(payload, axiosPrivate);

      if (response.success) {
        notify.success('Votre zone a été ajoutée avec succès');
        // You can navigate or take other actions here if necessary
      } else {
        notify.error('Une erreur s\'est produite, veuillez réessayer');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      notify.error('Une erreur s\'est produite');
    }
  };

  const handleSubmitEmailService = async (e) => {
    e.preventDefault();

    // const updatedState = { ...JSON.parse(sessionStorage.getItem('state') ?? '{}'), address, phone };
    // sessionStorage.setItem('state', JSON.stringify(updatedState));

    // console.log("eeee", selectedServices[0].domain.id)
    // Prepare the data for submission
    const payload = {
      email: email,
      zip_code: address.zip_code,
      user_id: auth?.user?.id || null,
      zone_id: auth?.user?.id || null
      // domain_id: auth?.user?.id || null,
      // is_zone: false,
      // is_service: true,
    };

    try {
      const response = await UnavailableZoneService.store(payload, axiosPrivate);

      if (response.success) {
        notify.success('Votre zone a été ajoutée avec succès');
        // You can navigate or take other actions here if necessary
      } else {
        notify.error('Une erreur s\'est produite, veuillez réessayer');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      notify.error('Une erreur s\'est produite');
    }
  };

  const loc = useLocation();
  const closeModal = () => setIsOpen(false);
  const closeModal2 = () => setIsOpen2(false);
  const closeModalservice = () => setIsOpenService(false);

  const openModal = () => setIsOpen(true);
  const openModal2 = () => {

    setIsOpen2(true)
  };
  const openModalservice = () => {

    setIsOpenService(true)
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleGoBack = () => {
    setIsEditing(false);
  };

  const onConfirm = () => {
    navigate('/hotels');
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onContact = () => {
    navigate('/contact');
  };
  const { selectedServices, duo } = loc.state || JSON.parse(sessionStorage.getItem('state') ?? '{}');
  useEffect(() => {
    if (auth?.user?.phone) {
      setPhone(auth.user.phone ?? "");
    }
  }, [auth.user]);
  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem('state') ?? '{}');
    if (savedState?.address) {
      setAddress(savedState.address);
    }
    // if (!selectedServices || selectedServices.length === 0) {
    //   navigate('/choose-service');
    // }

    // if (loc.state) {
    sessionStorage.setItem('state', JSON.stringify({ address, phone }));
    // }
  }, [selectedServices, navigate, loc.state]);

  function isZipCodeInRange(zipCode, range) {
    // Extraire les bornes de la plage
    const [start, end] = range.split('-').map(Number);

    // Convertir le zipCode en entier
    const zip = parseInt(zipCode, 10);

    console.log(start, "start")
    return zip >= start && zip <= end;
  }
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getProfil);

        console.log(response.data);
        isMounted && setUser(response.data);
      } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])


  const checkZipCodeInRange = async (zip_code) => {
    try {
      const zoneResponse = await ZoneService.index(null, axiosPrivate, auth?.accessToken ? false : true);
      console.log(zoneResponse, "zone");

      if (zoneResponse) {
        const zipNumeric = parseInt(zip_code, 10);
        let isInRange = false;
        console.log(zipNumeric, "zipNumeric")


        for (const zone of zoneResponse) {

          if (isZipCodeInRange(zipNumeric, zone.zip_code)) {
            isInRange = true;
            break;
          }
        }

        return isInRange;
      } else {
        console.error("Erreur lors de la récupération des zones.");
      }
    } catch (error) {
      console.error("Erreur lors de l'appel de ZoneService.index :", error);
    }
  };


  const checkAdress = async () => {
    console.log("auth", auth);
    try {
      const adressResponse = await AddressService.index({ with: 'hotel' }, axiosPrivate, auth?.accessToken ? false : true);


      let matchingAddress = null;

      // Recherche et stockage de l'adresse correspondante
      for (let responseAddress of adressResponse) {
        if (responseAddress.street?.toLowerCase().trim() === address.street?.toLowerCase().trim()) {
          matchingAddress = responseAddress;
          break; // Arrêter la recherche dès que l'adresse correspondante est trouvée
        }
      }

      console.log(matchingAddress)

      if (matchingAddress) {
        setHotelAddress(matchingAddress)
        // Vérifie si l'adresse correspondante a un hôtel associé
        const hasHotelId = matchingAddress.hotel.length > 0;
        console.log('Matching address with hotel found:', matchingAddress);
        console.log('Has hotel ID:', hasHotelId);
        return hasHotelId;
      } else {
        console.log('No matching address found.');
        return false;
      }

    } catch (error) {
      console.error("Erreur lors du chargement des domaines:", error);
    }
  };
  const fetchAllDomains = async () => {
    try {
      const response = await DomainService.index(
        { with: 'media,zones', sort: 'ordering' },
        axiosPrivate,
        !auth?.accessToken
      );

      const userZip = parseInt(address.zip_code, 10);

      const filteredDomains = response.filter(domain =>
        domain.is_active && domain.zones?.some(({ zip_code }) =>
          zip_code?.split(',').some(range => {
            const [min, max] = range.trim().split('-').map(Number);
            return max ? (userZip >= min && userZip <= max) : userZip === min;
          })
        )
      );

      console.log({ filteredDomains });

      return filteredDomains;

    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredDomains = await fetchAllDomains();

    const updatedState = { ...JSON.parse(sessionStorage.getItem('state') ?? '{}'), address, phone };
    sessionStorage.setItem('state', JSON.stringify(updatedState));

    console.log("zone check", await checkZipCodeInRange(address.zip_code))

    const isOneZone = await checkZipCodeInRange(address.zip_code);
    if (!isOneZone) {
      notify.warn('zone non desservie');
      openModal2()
      return;
    }


    if (filteredDomains.length == 0) {
      notify.warn('service non desservi');
      openModalservice()
      return;
    }

    const hasHotelId = await checkAdress();
    if (hasHotelId) {
      notify.warn('Ceci est adresse hotel');
      openModal()
      return;
    }
    if (!address.zip_code) {
      notify.warn('Veuillez saisir votre code postal');
    }
    navigate('/choose-service', { state: { address, phone } });
  };

  const handleSubmitAuth = async (event) => {
    event.preventDefault();
    const filteredDomains = await fetchAllDomains();
    const authAddress = user.user.primary_address;
    const formattedAddress = {
      ...authAddress,
      lng: authAddress.longitude,
      lat: authAddress.latitude,
    };
    delete formattedAddress.longitude;
    delete formattedAddress.latitude;

    setAddress(formattedAddress);

    // reset state before proceeding
    sessionStorage.setItem('state', JSON.stringify({ address: formattedAddress, phone: user?.user?.phone }));

    const isOneZone = await checkZipCodeInRange(formattedAddress.zip_code);
    if (!isOneZone) {
      notify.warn('zone non desservie');
      openModal2()
      return;
    }

    if (filteredDomains.length == 0) {
      notify.warn('service non desservi');
      openModalservice()
      return;
    }

    const hasHotelId = await checkAdress();
    if (hasHotelId) {
      notify.warn('Ceci est adresse hotel');
      openModal()
      return;
    }
    if (!formattedAddress.zip_code) {
      notify.warn('Veuillez saisir votre code postal');
    }

    navigate('/choose-service', { state: { address: formattedAddress, phone: user?.user?.phone } });
  };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: '',
      country_code: '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    console.log("places", addressComponents)
    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          newAddress.state = component.long_name;
          newAddress.state_code = component.short_name;
          break;
        case 'country':
          newAddress.country = component.long_name;
          newAddress.country_code = component.short_name;
          break;
        /*  case 'street_number':
           newAddress.street_number = component.long_name;
           break; */
        default:
          break;
      }
    }

    setAddress(newAddress);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <>
      <MenuHeader />
      <Nav page="adresse" />

      <div className="container ">
        <h2>Où souhaitez-vous le soin ?</h2>

        {!isEditing && user?.user?.firstname ? (
          <form onSubmit={handleSubmitAuth}>
            <div className="container container-profile">
              <div className="adress">
                <h3>Adresse</h3>
                <div className="rue">
                  {user?.user?.primary_address?.street} {user?.user?.primary_address?.street_number}
                  <br />
                  {user?.user?.primary_address?.zip_code} {user?.user?.primary_address?.city}
                  <br />
                  {user?.user?.phone}
                </div>
                <a href="#" className="edit" onClick={handleEditClick}>Edit</a>
              </div>
            </div>
            <br />
            <div className='btn-bottom'>
              <button type="submit" className="btn btn-primary">
                Suivant
              </button>
            </div>
          </form>

        ) : (
          <>
            <div className="container-location">
              <div className="form-group">
                <label htmlFor="street">Saisissez une adresse</label>
                <Autocomplete
                  apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
                  onPlaceSelected={handlePlaceSelect}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'CH' },
                  }}
                  style={{
                    width: '100%',
                    padding: '0 15px',
                    height: '60px',
                    border: '0',
                    borderRadius: '20px',
                    boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)',
                  }}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="phone">Saisissez votre téléphone</label>
                <input
                  type="tel"
                  id="phone"
                  className="form-control"
                  placeholder="Ex: +41 00 000 00 00"
                  defaultValue={user?.user?.phone}
                  onChange={handlePhoneChange}
                  maxLength="16"
                  required
                />
              </div> */}
            </div>
            {/* <button className="btn btn-secondary" onClick={handleGoBack}>
              Retour
            </button> */}
          </>
        )}
        {isEditing && address.lat && address.lng && (
          <form onSubmit={handleSubmit}>
            <div className="container-location">
              <div className="form-group">
                {/* <label htmlFor="street">Rue</label> */}
                <input
                  type="hidden"
                  id="street"
                  className="form-control"
                  value={address.street}
                  onChange={(e) => setAddress({ ...address, street: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                {/* <label htmlFor="state">Canton</label> */}
                <input
                  type="hidden"
                  id="state"
                  className="form-control"
                  value={address.state}
                  onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                {(!address.zip_code || address.zip_code.length < 8) && (
                  <>
                    <label htmlFor="postalCode">Code postal</label>
                    <input
                      type="text"
                      id="postalCode"
                      className="form-control"
                      value={address.zip_code}
                      onChange={(e) => setAddress({ ...address, zip_code: e.target.value })}
                      required
                    />
                  </>
                )}
              </div>
              <div className="form-group">
                {/* <label htmlFor="city">Ville</label> */}
                <input
                  type="hidden"
                  id="city"
                  className="form-control"
                  value={address.city}
                  onChange={(e) => setAddress({ ...address, city: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className='btn-bottom'>
              <button type="submit" className="btn btn-primary">
                Suivant
              </button>
            </div>
          </form>
        )}

      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal">

          <div className="modal">
            <p className="modal-text">
              Ceci est l’adresse d’un hotel partenaire, vous allez basculer sur le choix “FlySPA à l’hôtel”
            </p>
            <h2 className="modal-hotel-name"> {hotelAddress?.hotel?.name}
              <span className="modal-rating">⭐ 4.9/5</span>
            </h2>
            <p className="modal-distance">A 2 km du Centre Ville</p>
            <p className="modal-address">
              Adresse: <span className='address-detail'>{hotelAddress?.street_number} {hotelAddress?.street}, {hotelAddress?.zip_code} {hotelAddress?.city}, {hotelAddress?.country}</span>
            </p>
            <div className="modal-button-container">
              <button className="modal-cancel-button" onClick={closeModal}>Annuler</button>
              <button className="modal-ok-button" onClick={onConfirm}>OK</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="zone-not-served-container">
          <div className="popup-box">
            <button onClick={closeModal2} className="close-btn"><img src={croix} alt="Fermer" /></button>
            <h2>Zone Non Desservi</h2>
            <p>
              Restez informé quand nos services seront disponible à cette adresse.
              Merci de saisir votre email.
            </p>
            <form onSubmit={handleSubmitEmail}>
              <label htmlFor="email">Adresse email *</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="send-btn">Envoyer</button>
            </form>
            <button onClick={onContact} className="support-btn">Contacter le support</button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpenService}
        onRequestClose={closeModalservice}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="zone-not-served-container">
          <div className="popup-box">
            <button onClick={closeModalservice} className="close-btn"><img src={croix} alt="Fermer" /></button>
            <h2>Service Non Desservi</h2>
            <p>
              Restez informé quand nos services seront disponible à cette adresse.
              Merci de saisir votre email.            </p>
            <form onSubmit={handleSubmitEmail}>
              <label htmlFor="email">Adresse email *</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="send-btn">Envoyer</button>
            </form>
            <button onClick={onContact} className="support-btn">Contacter le support</button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChooseLocation;