// ValidationPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "../api/axios";
import { endpoints } from '../utils/utils';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
const ValidationPage = () => {
  const [otp, setOtp] = useState('');
  const navigation = useNavigate();
  const location = useLocation();
  const { email } = location.state || { email: '' };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Ici, vous pouvez ajouter la logique pour valider le code OTP
    console.log('Code OTP soumis:', email);
    try {
        const response = await axios.post(endpoints.verify, JSON.stringify({user_email : email, otp_code : otp}),{
          headers: { "Content-Type": "application/json" },
        });
        console.log('Réponse du serveur:', response.data);
        if(response.data.status !== false){
            Swal.fire({
                icon: 'success',
                title: 'Vérification réussie',
                text: 'Votre compte a été activé avec succès.',
              });
              setTimeout(() => {
                navigation('/login');
              }, 100);

        }

        // Ici, vous pouvez gérer la réponse du serveur (redirection, message de confirmation, etc.)
      } catch (error) {
        console.error('Erreur lors de l\'envoi des données:', error);
        // Ici, vous pouvez gérer les erreurs (affichage d'un message d'erreur, par exemple)
      }
    // Après validation réussie, vous pouvez rediriger l'utilisateur vers une autre page
    // par exemple, la page de confirmation d'inscription réussie

  };

  return (
    <div className="validation-inscription">
    <div className="container">
      <h2>Validation de l'inscription</h2>
      <p>Veuillez saisir le code OTP reçu par SMS.</p>
      <form onSubmit={handleSubmit}>
        <label>Code OTP:</label>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
        />
        <button type="submit">Valider</button>
      </form>
    </div>
    </div>
  );
};

export default ValidationPage;
