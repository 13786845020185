import React from 'react';
import { createRoot } from 'react-dom/client'; // Mettez à jour l'importation
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { register } from './utils/serviceWorker';
import { LoadingProvider } from './context/LoadingProvider';
import { NotificationProvider } from './context/NotificationProvider';
// Utilisez createRoot pour rendre votre application
const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Routes>
          <Route path="/*" element={<LoadingProvider><NotificationProvider><App /></NotificationProvider></LoadingProvider>} />
        </Routes>
      </GoogleOAuthProvider>
    </AuthProvider>
  </BrowserRouter>
);
register();