import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BookingService from '../services/booking.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import Header from './Header';
import coins from '../assets/images/coins.svg';
import { useLoading } from '../context/LoadingProvider';
import PromotionService from "../services/promotion.service";
import GeneralSettingService from '../services/general_setting.service';


function PaymentGift() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { selectedGift, address, giftDetails } = location.state;
  const { lng, lat, zip_code, street, street_number, state, state_code, country, country_code } = address || {};
  const [surcharge, setSurcharge] = useState('');

  useEffect(() => {
    const fetchSurcharge = async () => {
      try {
        const surcharge = await GeneralSettingService.index('setting=in:surcharge', axiosPrivate);
        setSurcharge(surcharge[0].value);
      } catch (error) {
        console.error('Error fetching surcharge data:', error);
      }
    };

    fetchSurcharge();
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let promoCode = '';
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      promoCode += characters.charAt(randomIndex);
    }

    const creationDate = new Date();
    const validityDate = new Date(creationDate.setFullYear(creationDate.getFullYear() + 2));

    let mappedType = '';

    if (giftDetails.configuration.type === 'voucher_percentage') {
      mappedType = 'PERCENT_OFF';
    } else if (giftDetails.configuration.type === 'voucher_fixe') {
      mappedType = 'FLATT_OFF';
    } else if (giftDetails.configuration.type === 'voucher_credit') {
      mappedType = 'FREE_CREDITS';
    } else if (giftDetails.configuration.type === 'package' || giftDetails.configuration.type === 'treatment') {
      mappedType = 'FREE';
    }

    const basePrice = Number(giftDetails.configuration.price);
    const finalPrice = basePrice + (zip_code ? Number(surcharge) : 0);

    const payload = {
      name: selectedGift.name + (' (') + giftDetails.configuration.title + (') '),
      code: promoCode,
      date_creation: new Date().toISOString().split('T')[0],
      date_validity: validityDate.toISOString().split('T')[0],
      type: mappedType,
      amount: giftDetails.configuration.value || giftDetails.configuration.original_price,
      total_usage: giftDetails.configuration.usage,
      usage_per_customer: giftDetails.configuration.usage,
      is_active: 0,
      all_clients: 1,
      is_gift: 1,
      product_id: giftDetails.configuration.product_id === "null" ? null : giftDetails.configuration.product_id,
      long_description: giftDetails.message,
      from_user_name: giftDetails.from_name,
      to_user_name: giftDetails.recipient_name,
      to_user_email: giftDetails.recipient_email,
      zip_code: zip_code,
      street: street,
      state: state,
      state_code: state_code,
      country: country,
      country_code: country_code,
      street_number: street_number,
      longitude: lng,
      latitude: lat,
      address_id: zip_code ? auth?.user?.primary_address_id : null,
      client_id: auth?.user?.id,
      price: finalPrice,
      clients: [],
      zones: [],
    };

    console.log({ payload })

    try {
      const response = await PromotionService.store(payload, axiosPrivate);
      loadLightbox(response.data.transaction_data.payment_lightbox, response.data.transaction_data.payment_url);
    } catch (error) {

    }

  };

  const loader = useLoading();

  const loadUrl = (paymentUrl) => {
    window.location.replace(paymentUrl);
  }
  const loadLightbox = (paymentLightboxScript, paymentUrl) => {
    loader.start();
    const script = document.createElement('script');
    script.src = paymentLightboxScript;
    script.async = true;
    script.onload = () => {
      window.LightboxCheckoutHandler.startPayment(null, () => { loader.stop(); loadUrl(paymentUrl) });
    };
    document.body.appendChild(script);
  }


  return (
    <>
      <Header />
      <div className="content-wrapper">
        <div className="content-wrapper duo-pay">

          {giftDetails && selectedGift && (
            <div className="column">
              <div className="summary-box">
                {selectedGift && (
                  <div className="name">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${selectedGift.media?.length ? selectedGift.media[0].original_url : coins})`,
                      }}
                    ></div>
                    <div className="title">
                      <div className="text-wrapper-2">
                        {selectedGift.name}
                      </div>
                    </div>
                  </div>
                )}

                <div className="div-2">
                  <div className="text-wrapper-5">{giftDetails.configuration.title}</div>
                </div>

                <div className="div-2">
                  <p className="text-wrapper-5">
                    Validitée 2 an
                  </p>
                  <div className="text-wrapper-6">{giftDetails.configuration.price} CHF</div>
                </div>
                {address.zip_code && (

                  <div className="div-2">
                    <p className="text-wrapper-5">
                      Physical gift card
                    </p>
                    <div className="text-wrapper-6">{surcharge} CHF</div>
                  </div>
                )}

                {address.zip_code && (
                  <div className="div-2">
                    <p className="text-wrapper-5">
                      {address?.street} {address?.streetnumber}
                      <br />
                      {address?.zip_code} {address?.city}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="column-2">
          <div className="total">
            <div className="price">
              <div className="list-item-2 list-item-3">
                <span>Total (TVA incluse)</span>
                <div className="text-wrapper-6">{Number(giftDetails.configuration.price) + (address.zip_code ? Number(surcharge) : 0)} CHF</div>
              </div>
            </div>

            <div className="checkbox-2">
              <input type="checkbox" className="checkbox" id="cgu" defaultChecked />
              <label htmlFor="cgu" className="accepter-les-CGU-et">
                <span>Accepter </span>
                <span className="text-wrapper-9">les CGU</span>
                <span> et la </span>
                <span className="text-wrapper-9">Politique de Confidentialité</span>
                <span> *</span>
              </label>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="button-validation"
            type="button"
          >
            {Number(giftDetails.configuration.price) + (address.zip_code ? Number(surcharge) : 0)} CHF
          </button>
        </div>

      </div>
    </>
  );
}

export default PaymentGift;
