import React, { Fragment, useState, useEffect } from 'react'
import MenuHeader from './MenuHeader'
import { Map } from './google_map';
import HotelList from './HotelList';
import HotelDetails from './HotelDetails';
import HotelService from '../services/hotel.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

function Hotel() {
  const [hotels, setHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const axionPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showList, setShowList] = useState(true);
  const [showHotelDetails, setShowHotelDetails] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const fetchHotels = async () => {
    try {
      const hotels = await HotelService.index({ with: 'media,address', is_only_internal: false }, axionPrivate, auth?.user ? false : true)
      setHotels(hotels);
      setFilteredHotels(hotels);
    } catch (error) {

    }
  }
  useEffect(() => {
    fetchHotels();
  }, [])

  const handleShowHotelMapClick = (hotel) => {
    setSelectedHotel(hotel);
    setShowList(false);
    setShowHotelDetails(false);
  };

  const handleHotelClick = (hotel) => {
    setSelectedHotel(hotel);
    setShowHotelDetails(true)
  };

  const handleHotelFilter = (filter) => {
    setFilteredHotels(hotels.filter(hotel => hotel.name.toLowerCase().includes(filter.toLowerCase())));
  };

  const handleMapClick = () => {
    setShowList(false);
    setShowHotelDetails(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    if (screenWidth < 992 && showHotelDetails) {
      setShowList(false);
    }
  }, [screenWidth, showHotelDetails]);

  useEffect(() => {
    if (screenWidth < 992 && showList) {
      setShowHotelDetails(false);
    }
  }, [screenWidth, showList]);
  return (
    <Fragment>
      <MenuHeader />
      <Map hotels={filteredHotels} selectedHotel={selectedHotel} onClick={handleMapClick} onHotelClick={handleHotelClick} />
      {!showList && (<button className='toggle-hotel-view' onClick={() => setShowList(true)}><img src='/assets/images/icon-hotel.svg' height={30} /></button>)}
      {showList && (<HotelList hotels={filteredHotels} selectedHotel={selectedHotel} onHotelShowMapClick={handleShowHotelMapClick} onHotelClick={handleHotelClick} onHotelFilter={handleHotelFilter} />)}
      {selectedHotel && showHotelDetails && (
        <HotelDetails hotel={selectedHotel} center={!showList} />
      )}
    </Fragment>
  );
}

export default Hotel;
