import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import NavHotel from './NavHotel';
import user from '../assets/images/default.png';
import croix from "../assets/images/close.png";
import back from "../assets/images/back.png";
import MenuHeader from './MenuHeader';
import TherapistService from '../services/therapist.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from "react-modal";
import DateTimeSelector from './DateTimeSelector';
import HeaderTherapist from './HeaderTherapist';
import ReviewList from './ReviewList';
import Informations from './Informations';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';
import UserService from '../services/user.service';
import CalendarManagementService from '../services/calendar_management.service';
import GeneralSettingService from '../services/general_setting.service';

const customStyles = {
  content: {
    width: "33%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};




Modal.setAppElement("#root");

function ChooseHotelTherapist() {
  const [selectedTherapist, setSelectedTherapist] = useState([{ id: 0 }]);
  const [therapists, setTherapist] = useState('');
  const [therapistsOther, setTherapistOther] = useState('');
  const [currentTherapist, setCurrentTherapist] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [filter, setFilter] = useState(null)
  const [visibleDays, setVisibleDaysCount] = useState(10);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [availableHours, setAvailableHours] = useState([]);
  const [buffertime, setBufferTime] = useState([]);
  const [filters, setFilters] = useState({
    with: 'primary_address,zones,managers,media,categories,therapist_bookings,slots',
    sort: 'firstname'
  });
  const location = useLocation();
  const { auth } = useAuth();
  const loc = useLocation();
  const [state, setState] = useState(loc.state || JSON.parse(sessionStorage.getItem('state') ?? '{}'));
  const { hotel_address, duo, selectedServices, hotelId } = state;
  const { longitude, latitude, zip_code } = hotel_address || {};
  const notify = useNotifications();
  const totalDuration = selectedServices?.reduce((total, service, index) => {
    const duration = service?.service.duration ? parseInt(service.service.duration, 10) : 0;
    return total + duration;
  }, 0);
  const totalPrice = selectedServices?.reduce((total, service, index) => {
    console.log({ service })
    const price = service?.service.price ? service.service.price : 0;
    console.log({ price })
    return total + price;
  }, 0);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    tags: "",
    is_asca: "",
    is_rme: "",
    civility: "",
  });

  function formatISODate(isoDate) {
    return new Date(isoDate).toLocaleDateString('en-CA').split("T")[0];
  }

  function addedTherapist(therapist) {
    setSelectedTherapist((prevTherapists) => {
      if (therapist.id === 0) {
        return [therapist];
      } else {
        const updatedTherapists = prevTherapists.some(t => t.id === 0) ? [] : prevTherapists;
        if (updatedTherapists.some(t => t.id === therapist.id)) {
          return updatedTherapists.filter(t => t.id !== therapist.id);
        } else if (!duo) {
          return [therapist];
        } else {
          return [...updatedTherapists, therapist];
        }
      }
    });
  }

  useEffect(() => {
    const savedState = sessionStorage.getItem('state');
    console.log({ hotel_address })
    console.log({ selectedServices })
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      console.log(parsedState);
      setState(parsedState);

      if (parsedState.selectedDate) setSelectedDate(new Date(parsedState.selectedDate));
      if (parsedState.selectedTime) setSelectedTime(parsedState.selectedTime);
      if (parsedState.selectedTherapist) setSelectedTherapist(parsedState.selectedTherapist);
      setSelectedProductIds(selectedServices.map(service => service.service.id));


    }
  }, []);

  useEffect(() => {
    if (!selectedServices || selectedServices.length === 0) {
      navigate('/choose-hotel-service');
    }
  }, [selectedServices, hotel_address, navigate]);

  useEffect(() => {
    fetchUnavailableDates();
  }, [selectedDate, visibleDays]);


  useEffect(() => {
    const now = new Date();
    let timeToUse = selectedTime;
    if (!selectedTime) {
      const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
      now.setMinutes(nextQuarterHour);
      now.setSeconds(0);
      timeToUse = now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
    }
    if (selectedDate && selectedTime) {
      fetchTherapist(formatISODate(selectedDate), timeToUse, totalDuration, latitude, longitude, zip_code);
    }
    // fetchUnavailableDates();
    fetchBufferTime();
    fetchAvailableHours();
    updateDaysToShow(selectedDate || new Date());
  }, [selectedDate, totalDuration, longitude, latitude, zip_code, selectedProductIds, visibleDays]);

  const fetchUnavailableDates = async () => {

    try {
      const startDate = (selectedDate || new Date()).toLocaleDateString('en-CA').split('T')[0];
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + visibleDays + 1);
      const formattedEndDate = endDate.toLocaleDateString('en-CA').split('T')[0];

      const dates = await fetchCalendarData(startDate, formattedEndDate);
      const formattedDates = dates.map(date => new Date(date.split('T')[0]).toDateString());
      console.log('formattedDates', formattedDates);
      setUnavailableDates(formattedDates);
    } catch (error) {
      console.error('Error fetching unavailable dates:', error);
    }

  };

  const fetchCalendarData = async (start, end) => {
    try {
      const response = await CalendarManagementService.index(
        {
          with: 'therapist.media,therapist.zones,calendars_managements_slots.slot',
          date: `between:${start},${end}`,
        },
        axiosPrivate,
        auth?.accessToken ? false : true
      );
      console.log('response calendrier :', response);
      const filteredDates = [];
      response.forEach(entry => {
        if (entry.date) {
          filteredDates.push(entry.date);
        }
      });
      // console.log('filteredDates', filteredDates);


      return filteredDates;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const fetchBufferTime = async () => {
    try {
      const bufferTime = await GeneralSettingService.index('setting=in:buffer_before,buffer_after', axiosPrivate, auth?.accessToken ? false : true);

      const bufferBefore = bufferTime.find(item => item.setting === 'buffer_before')?.value || '0';
      const bufferAfter = bufferTime.find(item => item.setting === 'buffer_after')?.value || '0';

      setBufferTime({ bufferBefore, bufferAfter });
      // console.log('Buffer:', buffertime);
    } catch (error) {
      console.error('Error fetching buffer time:', error);
    }
  };
  const fetchAvailableHours = async () => {
    if (selectedDate) {
      try {
        const responseSelectedDate = await CalendarManagementService.index(
          {
            date: new Date(selectedDate).toLocaleDateString('en-CA').split('T')[0],
            with: 'therapist.media,therapist.zones,calendars_managements_slots.slot',
          },
          axiosPrivate,
          auth?.accessToken ? false : true
        );

        const filteredSlots = [];
        responseSelectedDate.forEach((entry) => {
          if (entry.calendars_managements_slots) {
            entry.calendars_managements_slots.forEach((slot) => {
              if (slot.status === 'AVAILABLE' || slot.status === 'OVER_TIME') {
                filteredSlots.push({
                  start_time: slot.slot.start_time,
                  end_time: slot.slot.end_time,
                });
              }
            });
          }
        });

        // console.log('filteredSlots:', filteredSlots);
        // setAvailableHours(filteredSlots);
        filteredSlots.sort((a, b) => a.start_time.localeCompare(b.start_time));

        const mergedSlots = [];
        filteredSlots.forEach((slot) => {
          if (
            mergedSlots.length > 0 &&
            mergedSlots[mergedSlots.length - 1].end_time === slot.start_time
          ) {
            mergedSlots[mergedSlots.length - 1].end_time = slot.end_time;
            mergedSlots[mergedSlots.length - 1].status = slot.status;
          } else {
            mergedSlots.push({ ...slot });
          }
        });

        // console.log('mergedSlots1:', mergedSlots);
        setAvailableHours(mergedSlots);
      } catch (error) {
        console.error('Error fetching available hours:', error);
      }
    }
  };

  const fetchTherapist = async (date, time, duration, longitude, latitude, zip_code, filtredData) => {
    console.log(' long, lat : ', longitude, latitude)
    try {
      // Lancer les deux requêtes en parallèle avec Promise.all
      const [therapistResponse, allTherapists] = await Promise.all([
        TherapistService.store({
          date,
          time,
          duration,
          lng: longitude,
          lat: latitude,
          zip_code,
          is_duo: duo,
          is_hotel: true,
        }, axiosPrivate, { with: `media`, ...formData }),

        UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate, true)
      ]);

      if (duo) {
        therapistResponse = [...new Set([].concat(...Object.values(therapistResponse)))];
      }
      setTherapist(therapistResponse);
      console.log("Fetched Therapists Response:", therapistResponse);

      // Récupérer les IDs des thérapeutes déjà sélectionnés
      const therapistIds = new Set(therapistResponse.map(t => t.id));

      // Filtrage basé sur le zip_code uniquement si nécessaire
      const filteredTherapists = zip_code
        ? allTherapists.filter(therapist =>
          !therapistIds.has(therapist.id) &&
          therapist.zones?.some(zone => {
            const [startZip, endZip] = zone.zip_code.split("-").map(Number);
            return zip_code >= startZip && zip_code <= endZip;
          })
        )
        : allTherapists;

      setTherapistOther(filteredTherapists);
      console.log("Filtered Therapists:", filteredTherapists);

    } catch (error) {
      console.error("Erreur lors du chargement des thérapeutes:", error);
    }

  };


  const openModal = () => setIsOpen(true);
  const openModal2 = (therapist) => {
    console.log(therapist)
    setCurrentTherapist(therapist)
    setIsOpen2(true)
  };

  const closeModal = () => setIsOpen(false);
  const closeModal2 = () => setIsOpen2(false);

  const handleSubmit = (event) => {
    event?.preventDefault();
    console.log(selectedTherapist)

    const selectedTherapists = selectedTherapist[0]?.id === 0 ?
      (duo && therapists.length >= 2 ? [therapists[0], therapists[1]] :
        !duo && therapists.length >= 1 ? [therapists[0]] : [{ id: 0 }]) : selectedTherapist;

    if (duo && selectedTherapists.length !== 2) {
      notify.warn('Veuillez sélectionner deux thérapeutes pour un duo.');
      return;
    } else if (!duo && selectedTherapists.length !== 1) {
      notify.warn('Veuillez sélectionner un thérapeute.');
      return;
    }

    const paymentState = {
      hotel_address,
      duo,
      totalDuration,
      totalPrice,
      selectedServices,
      hotelId,
      selectedTherapist: selectedTherapists,
      selectedDate: selectedDate.toLocaleDateString('en-CA'),
      selectedTime
    };
    const hasMatchingId = therapists.some(therapist =>
      selectedTherapists.some(selected => selected.id === therapist.id)
    );

    sessionStorage.setItem('state', JSON.stringify(paymentState));
    if (!auth || Object.keys(auth).length === 0) {
      sessionStorage.setItem('redirectAfterLogin', '/choose-therapist');
      navigate('/login');
      return;
    }
    if (hasMatchingId)
      navigate('/hotel-payment', {
        state: paymentState
      });
    else
      navigate('/flypro-service', {
        state: paymentState
      });
  };


  const handleSubmitFilter = (event) => {
    event.preventDefault();
    console.log(formData)
    setFilter(formData);
    setTimeout(() => {
      handleHourClick(selectedTime)
    }, 1000);

    closeModal();
  };


  const handleMonthChange = (direction) => {
    const newDate = new Date(selectedDate || new Date());

    const currentDay = newDate.getDate();
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + direction);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (newDate < new Date(today.getFullYear(), today.getMonth(), 1)) return;

    const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
    newDate.setDate(Math.min(currentDay, daysInNewMonth));

    setSelectedDate(newDate);
    updateDaysToShow(newDate);
  };

  const handleDayChange = (direction) => {
    const container = document.querySelector('.row-day');
    if (!container) return;

    const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
    const containerWidth = container.clientWidth;
    const visibleItems = Math.floor(containerWidth / dayWidth);
    const daysToScroll = Math.max(1, visibleItems - 0.5);

    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + direction * daysToScroll);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (newDate < today) {
      newDate.setDate(today.getDate());
    };
    setSelectedDate(newDate);
    setSelectedTime(null);
    setTherapist('');
    updateDaysToShow(newDate);
    if (selectedTime === null)
      return;
    //fetchTherapist(formatISODate(newDate), selectedTime, totalDuration, lat, lng, zip_code);

  };
  const redirectToPartnerHotels = () => {
    window.location.href = '/hotels';
  };

  function handleDayClick(date) {
    setSelectedDate(date);
    setSelectedTime(null)
    setTherapist('')
    const now = new Date();
    let initialTime;
    // if (date.getDay() === 0) {
    //   setShowPopup(true);
    // }

    console.log(now < new Date()
      ? 'disabled'
      : now?.toDateString() === selectedDate?.toDateString()
        ? 'selected'
        : '')

    if (selectedTime === null)
      return;

    if (date?.toDateString() === now?.toDateString()) {
      const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
      now.setMinutes(nextQuarterHour);
      now.setSeconds(0);

      initialTime = now;
    } else {
      initialTime = new Date(date);
      initialTime.setHours(8, 0, 0, 0);
    }

    //setSelectedTime(initialTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
    //fetchTherapist(formatISODate(date), selectedTime, totalDuration, lat, lng, zip_code);
  }


  function handleHourClick(hour) {
    setSelectedTime(hour);
    fetchTherapist(formatISODate(selectedDate), hour, totalDuration, longitude, latitude, zip_code);
  }



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(formData)
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox"
        ? prevState[name] === value
          ? "" // Uncheck si déjà sélectionné
          : value
        : value,
    }));
  };

  const handleResetFilter = () => {

    setFormData({
      tags: "",
      is_asca: "",
      is_rme: "",
      civility: "",
    })

  }

  const civilityLabels = {
    MS: "Femme",
    MR: "Homme",
  };

  const tagsLabels = {
    "contains:english": "Anglais",
    "contains:french": "Français",
    "contains:deutch": "Allemand",
  };

  const getFilterText = () => {
    let filters = [];


    if (formData.civility && civilityLabels[formData.civility]) {
      filters.push(civilityLabels[formData.civility]);
    }


    if (formData.tags && tagsLabels[formData.tags]) {
      filters.push(tagsLabels[formData.tags]);
    }


    if (formData.is_asca === "1") {
      filters.push("ASCA");
    }


    if (formData.is_rme === "1") {
      filters.push("RME");
    }
    console.log(filters.length > 0 ? `Filter: ${filters.join(" - ")}` : "")
    return filters.length > 0 ? `Filter: ${filters.join(" - ")}` : "";
  };


  const getMonthName = (date) => {
    // const date = new Date();
    // date.setMonth(monthIndex);
    return date.toLocaleString('fr-FR', { month: 'long' });
  };

  const getDayName = (date) => {
    return date.toLocaleString('fr-FR', { weekday: 'short' });
  };

  const getNextHourSlots = (date) => {
    const now = new Date();
    const hours = [];

    if (date?.toDateString() === now?.toDateString()) {
      const minutes = now.getMinutes();
      const nextQuarterHour = Math.ceil(minutes / 15) * 15;
      now.setMinutes(nextQuarterHour);
      now.setSeconds(0);

      for (let i = 0; i < 57; i++) {
        const slot = new Date(now.getTime() + i * 15 * 60000);
        if (slot.getHours() >= 8 && slot.getHours() < 22) {
          hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
        }
      }
    } else {
      const startOfDay = new Date(date);
      startOfDay.setHours(8, 0, 0, 0);

      for (let i = 0; i < 57; i++) {
        const slot = new Date(startOfDay.getTime() + i * 15 * 60000);
        if (slot.getHours() >= 8 && slot.getHours() < 22) {
          hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
        }
      }
    }

    return hours;
  };

  const scrollLeft = () => {
    const container = document.querySelector('.row-day');
    if (!container) return;

    const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
    const visibleItems = Math.floor(container.clientWidth / dayWidth);
    const scrollDistance = dayWidth * (visibleItems - 0.5);

    container.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const container = document.querySelector('.row-day');
    if (!container) return;

    const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
    const visibleItems = Math.floor(container.clientWidth / dayWidth);
    const scrollDistance = dayWidth * (visibleItems - 0.5);

    container.scrollBy({ left: scrollDistance, behavior: 'smooth' });
  };

  const getInitialDaysToShow = () => {
    const days = [];
    const today = new Date();
    for (let i = 0; i < 9; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const [daysToShow, setDaysToShow] = useState(() => getInitialDaysToShow());
  const [showPopup, setShowPopup] = useState(false);

  const updateDaysToShow = (baseDate) => {
    const days = [];
    for (let i = 0; i < 9; i++) {
      const day = new Date(baseDate);
      day.setDate(baseDate.getDate() + i);
      days.push(day);
    }
    setDaysToShow(days);
  };

  const hours = getNextHourSlots(selectedDate);
  // sessionStorage.removeItem("previousPath");

  return (
    <>
      <MenuHeader />
      <NavHotel page="therapist" />
      <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
        {!modalIsOpen2 && <h2>Choisissez votre masseur et la date</h2>}
      </div>
      <form className="form-masseur" onSubmit={handleSubmit}>
        {modalIsOpen2 ? <>
          <div className="contentModal">

            <HeaderTherapist currentTherapist={currentTherapist} closeModal2={closeModal2} />
            <form className="form-masseur" onSubmit={handleSubmit}>
              <DateTimeSelector selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTime={selectedTime}
                modalIsOpen2={modalIsOpen2}
                setSelectedTime={setSelectedTime}
                handleMonthChange={handleMonthChange}
                handleDayChange={handleDayChange}
                handleDayClick={handleDayClick}
                handleHourClick={handleHourClick}
                daysToShow={daysToShow}
                hours={hours}
                totalPrice={totalPrice}
                scrollLeft={scrollLeft}
                scrollRight={scrollRight}
                openModal={openModal}
                getMonthName={getMonthName}
                getDayName={getDayName}
                getFilterText={getFilterText()}
                handleResetFilter={handleResetFilter}
                unavailableDates={unavailableDates}
                availableHours={availableHours}
                buffertime={buffertime}
                totalDuration={totalDuration}
              />

            </form>
            <ReviewList />
            <Informations currentTherapist={currentTherapist} />
          </div></> : <>
          <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
            <DateTimeSelector
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              handleMonthChange={handleMonthChange}
              handleDayChange={handleDayChange}
              handleDayClick={handleDayClick}
              handleHourClick={handleHourClick}
              daysToShow={daysToShow}
              hours={hours}
              totalPrice={totalPrice}
              scrollLeft={scrollLeft}
              scrollRight={scrollRight}
              openModal={openModal}
              getMonthName={getMonthName}
              getDayName={getDayName}
              getFilterText={getFilterText()}
              unavailableDates={unavailableDates}
              availableHours={availableHours}
              buffertime={buffertime}
              totalDuration={totalDuration}
              handleResetFilter={handleResetFilter}
            />
            <div className="form-group group-masseur">
              {therapists.length > 0 &&
                <div className="therapist-item" onClick={() => { addedTherapist({ id: 0 }); }}>
                  <img src={user} alt="default" className="therapist-image" />
                  <div className="therapist-info">
                    <p className="name">Aucune préférence </p>
                    <button
                      type="button"
                      className={`btn-outline-primary default ${selectedTherapist.some(t => t.id === 0) ? 'selected' : ''}`}

                    >
                      &gt;
                    </button>
                  </div>
                </div>
              }
              {therapists.length > 0 && therapists?.map((therapist) => (
                <div key={therapist.id} className="therapist-item" onClick={() => addedTherapist(therapist)}>
                  <img onClick={() => openModal2(therapist)} src={therapist.media?.length ? therapist.media[0].original_url : user} alt={therapist?.name} className="therapist-image" />
                  <div className="therapist-info">
                    <p className="name">{therapist?.firstname}</p>
                    <p className="note">{therapist?.extra?.rating}/5</p>
                    <button
                      type="button"
                      className={`btn-outline-primary ${selectedTherapist.some(t => t.id === therapist.id) ? 'selected' : ''}`}
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              ))}

            </div>
            {therapistsOther.length > 0 && !duo && (
              <>
                <div className='other'>Disponible sur d'autres créneaux</div>
                <div className="form-group group-masseur">

                  {therapistsOther?.map((therapist) => (
                    <div key={therapist.id} className="therapist-item" onClick={() => addedTherapist(therapist)}>
                      <img onClick={() => openModal2(therapist)} src={therapist.media.length ? therapist.media[0].original_url : user} alt={therapist?.name} className="therapist-image" />
                      <div className="therapist-info">
                        <p className="name">{therapist?.firstname}</p>
                        <p className="note">{therapist?.extra?.rating ? therapist.extra.rating.toFixed(1) : "5"}/5</p>
                        <button
                          type="button"
                          className={`btn-outline-primary ${selectedTherapist.some(t => t.id === therapist.id) ? 'selected' : ''}`}
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

        </>}

        <div className='btn-bottom'>
          <button type="submit" className="btn btn-primary">
            Suivant
          </button>
        </div>

      </form>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>No at-home service on Sundays, but our services are available in our partner hotel.</p>
            <button onClick={redirectToPartnerHotels} className="btn redirect-button">
              Choose a Partner Hotel
            </button>
            <button onClick={() => setShowPopup(false)} className="btn close-button">
              Close
            </button>
          </div>
        </div>
      )}


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal">
          <div className="headerModal modal-icone">
            <a onClick={closeModal}>
              <img src={back} alt="Fermer" />
            </a>
            <a onClick={closeModal}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <div className="form-inscription">
            <form onSubmit={handleSubmitFilter}>

              <div className="form-choix">
                <div className="form-radio">
                  <input
                    type="checkbox"
                    name="civility"
                    value="MR"
                    checked={formData.civility === "MR"}
                    id="monsieur"
                    onChange={handleChange}
                  />
                  <label htmlFor="monsieur">Homme</label>
                </div>
                <div className="form-radio">
                  <input
                    type="checkbox"
                    name="civility"
                    value="MS"
                    id="madame"
                    checked={formData.civility === "MS"}
                    onChange={handleChange}
                  />
                  <label htmlFor="madame">Femme</label>
                </div>
              </div>
              <div className='flex-row gap-10'>
                <div className="form-text">
                  <label>Language</label>
                  <select defaultValue={formData.tags} name="tags" onChange={handleChange}>
                    <option value="contains:french">Français</option>
                    <option value="contains:english">Anglais</option>
                  </select>
                </div>
              </div>
              <div className="form-choix">
                <div className="form-radio">
                  <input
                    type="checkbox"
                    name="is_asca"
                    value="1"
                    id="is_asca"
                    checked={formData.is_asca === "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="is_asca">ASCA</label>
                </div>
                <div className="form-radio">
                  <input
                    type="checkbox"
                    name="is_rme"
                    value="1"
                    id="is_rme"
                    checked={formData.is_rme === "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="is_rme">RME</label>
                </div>
              </div>

              <div className="form-button">
                <button className='btn-submit' type="submit">Filter</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>


    </>
  );
}
export default ChooseHotelTherapist;

