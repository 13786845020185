import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import UserService from '../services/user.service';
import { Accordion, AccordionDetails, AccordionSummary, Box, createTheme, IconButton, ListSubheader, Menu, MenuItem, MenuList, Tab, Tabs, ThemeProvider, Typography } from '@mui/material';
import { ExpandMoreOutlined, Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material'
const MenuHeader = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#eedd5f'
      },
      secondary: {
        main: 'rgba(57, 61, 68, 1)'
      }
    }
  })
  const [selected, setSelected] = useState('home');
  const [user, setUser] = useState();

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [burgerMenuVisible, setBurgerMenuVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuVisible(!burgerMenuVisible);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {

        // const response = await axiosPrivate.get(endpoints.getProfil);
        const response = await UserService.currentUser({ with: 'notifications' }, axiosPrivate)
        console.log(response)
        isMounted && setUser(response);
      } catch (err) {
        console.error(err);
      }
    };

    if (!auth || Object.keys(auth).length === 0) {
      return;
    }
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);


  const unreadCount = user?.user?.notifications?.filter(
    (notification) => notification.is_read !== true
  ).length || 0;

  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabs = [{ label: 'Acceuil', link: '/' }, { label: 'Services', sub_tabs: [{ label: 'Choisissez votre soin', link: '/choose-location' }, { label: 'Choisissez votre hotel partenaire', link: '/hotels' }] }, { label: 'FlyPro', link: '/location-pro' }, { label: 'Commandes', link: '/commande' }, { label: 'Contact', link: '/contact' }, { label: 'Besoin d\'aide ?', link: '/Profile' }];
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  useEffect(() => {
    const path = window.location.pathname;
    const index = tabs.findIndex((tab) => tab?.link === path || tab.sub_tabs?.find((sub_tab) => sub_tab.link === path));
    setSelectedTab(index === -1 ? 0 : index);
  }, [window.location.pathname]);

  const TabWithSubs = ({ tab, index }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }
    return (
      <>
        <Tab wrapped={true} label={tab.label} icon={<img src='assets/images/arrow_down.svg' />} iconPosition='end' value={index} onClick={handleClick} sx={{ height: '100%' }} />
        <Menu
          id="sub-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          color='secondary'
          onClose={handleClose}
        >
          {tab.sub_tabs.map((sub_tab, sub_index) => (
            <MenuItem key={sub_index} onClick={() => { handleClose(); navigate(sub_tab.link) }}>
              {sub_tab.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  const MenuWithSubs = ({ tab, index }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }
    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span">{tab?.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tab?.sub_tabs.map((sub_tab, sub_index) => (
              <MenuItem key={sub_index} onClick={() => { handleClose(); navigate(sub_tab.link) }}>
                {sub_tab.label}
              </MenuItem>
            ))}
          </AccordionDetails>
        </Accordion>
        {/* <MenuItem label={tab.label} icon={<img src='assets/images/arrow_down.svg' />} iconPosition='end' value={index} onClick={handleClick} sx={{ height: '100%' }} >
          {tab.label} <img src='assets/images/arrow_down.svg' />
        </MenuItem> */}
      </>
    )
  }
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  }
  return (
    <div className="menu-header">
      <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='menu-header-mobile'>
        <IconButton onClick={handleMenuClick}>{Boolean(menuAnchorEl) ? (<MenuOpenIcon />) : (<MenuIcon />)}</IconButton>
        <Link to='/'><img src='assets/images/logo.svg' alt='FlySpa Logo' className='logo' /></Link>
        <Menu
          id="menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <Box sx={{ padding: '0 10px' }}>
            {tabs.map((tab, index) => (
              tab.sub_tabs?.length ? (
                <MenuWithSubs key={index} tab={tab} index={index} />
              ) : (
                <MenuItem key={index} onClick={() => { handleMenuClose(); navigate(tab?.link) }}>
                  {tab.label}
                </MenuItem>
              )
            ))}
          </Box>

        </Menu>
      </Box>


      <div className="menu">
        <div className="menu-left">
          <ThemeProvider theme={theme}>
            <Box sx={{ width: 'fit-content', margin: '0 auto', height: '100%' }}>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='secondary'
                defaultValue={0}
                sx={{ height: '100%' }}
              >
                {tabs.map((tab, index) => tab.sub_tabs?.length ? (
                  <TabWithSubs key={index} tab={tab} />
                ) : (
                  <Tab key={index} label={tab.label} value={index} LinkComponent={Link} to={tab?.link} sx={{ height: '100%' }} />
                ))}
              </Tabs>
            </Box>
          </ThemeProvider>
        </div>
        {user && (
          <div className="menu-right">
            <ul>
              <li><a href="/notifications" className="notif"><span>{unreadCount}</span></a></li>
              <li><a href="/Profile" className="account">{user?.user?.extra?.initials}</a></li>
            </ul>
          </div>
        )} {!user && (
          <div className="menu-right">
            <ul>
              <li><a href="/login" className="login"><img src='assets/images/icon-login.svg' alt='login' /></a></li>
            </ul>
          </div>
        )}
      </div>
    </div >
  );
}

export default MenuHeader;
