import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import Nav from './Nav';
import croix from "../assets/images/close-black.png";
import Modal from "react-modal";

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DomainService from '../services/domain.service';
import CategoryService from '../services/category.service';
import ProductService from '../services/product.service';

import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';

import remove from '../assets/images/remove.png';
import people from '../assets/images/people.png';
import { Button, Container, createTheme, IconButton, ThemeProvider } from '@mui/material';

const customStyles = {
  content: {
    width: "33%",
    top: "50%",
    padding: "40px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "450px",
    margin: "auto",
    textAlign: "center",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const theme = createTheme({
  palette: {
    white: {
      "main": "#FFFFFF",
      "light": "#FFFFFF",
      "dark": "#FFFFFF",
      "contrastText": "#FFFFFF"
    },
    dark_yellow: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#FAEF9B",
    },
    dark_white: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#FFFFFF",
    },
    dark: {
      "main": "#393D44",
      "light": "#393D44",
      "dark": "#393D44",
      "contrastText": "#393D44"
    }
  },
});
function ChooseService() {
  const [domains, setDomains] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [modalIsOpenService, setIsOpenService] = useState(false);
  const closeModalservice = () => setIsOpenService(false);
  const openModalservice = () => setIsOpenService(true);
  const [hasModalShown, setHasModalShown] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const notify = useNotifications();
  const { auth } = useAuth();
  const [showDescriptionFor, setShowDescriptionFor] = useState({
  });
  const [showProductList, setShowProductList] = useState({

  });
  const location = useLocation();
  const { selectedServices: prevSelectedServices, duo: prevDuo, address: prevAddress, phone: prevPhone } = location.state || {};
  const phone = prevPhone || JSON.parse(sessionStorage.getItem('state'))?.phone || "";
  const locationState = location.state || {};
  const savedState = JSON.parse(sessionStorage.getItem('state') || '{}');
  const updatedState = { ...savedState, ...locationState, };
  const address = updatedState?.address || savedState?.address;
  const [selectedServices, setSelectedServices] = useState(prevSelectedServices || [
    { domain: null, category: null, subCategory: null, product: null, cat_has_products: false, categories: [], sub_categories: [], products: [], sub_cat_has_products: false, cat_has_subcategories: false, showDescriptionForId: null, showDescriptionForType: null, showProductsForId: null, showProductsForType: null }
  ]);

  const fetchAllDomains = async () => {
    try {
      const response = await DomainService.index(
        { with: 'media,zones', sort: 'ordering' },
        axiosPrivate,
        !auth?.accessToken
      );

      const userZip = parseInt(address.zip_code, 10);

      const filteredDomains = response.filter(domain =>
        domain.is_active && domain.zones?.some(({ zip_code }) =>
          zip_code?.split(',').some(range => {
            const [min, max] = range.trim().split('-').map(Number);
            return max ? (userZip >= min && userZip <= max) : userZip === min;
          })
        )
      );

      console.log({ filteredDomains });
      setDomains(filteredDomains);
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };

  const fetchCategories = async (domainId) => {
    try {
      const response = await CategoryService.index(
        { is_hidden: false, domain_id: domainId, parent_id: null, is_active: 1, with: 'media,subcategories' },
        axiosPrivate,
        auth?.accessToken ? false : true
      );

      // subcategories with`is_hidden=false`
      const filteredCategories = response.map(category => ({
        ...category,
        sub_categories: category.subcategories
          ? category.subcategories.filter(sub => !sub.is_hidden)
          : []
      }));

      setCategories(filteredCategories);
      return filteredCategories;
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    return [];
  };

  const fetchProducts = async (catId) => {
    try {
      const response = await ProductService.index(
        { is_hidden: false, category_id: catId, with: 'media', is_active: 1 },
        axiosPrivate,
        auth?.accessToken ? false : true
      );
      setProducts(response);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    return [];
  };

  useEffect(() => {
    // const savedState = JSON.parse(sessionStorage.getItem('chooseServiceState') ?? '{}');
    // if (savedState.selectedServices) {
    //   setSelectedServices(savedState.selectedServices);
    // }
    // if (savedState.isActive !== undefined) {
    //   setIsActive(savedState.isActive);
    // }
    fetchAllDomains();
  }, []);

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  // When select a domain
  const handleDomainSelect = async (personIndex, domainObj) => {
    const updated = [...selectedServices];
    console.log("CC dom", updated)
    console.log("cc domain persoin", personIndex)
    console.log({ domainObj })

    setShowDescriptionFor({ type: null, id: null });

    const categories = await fetchCategories(domainObj.id);
    console.log({ categories });

    updated[personIndex] = {
      domain: domainObj,
      category: null,
      subCategory: null,
      product: null,
      categories: categories,
      sub_categories: [],
      products: [],
      cat_has_products: false,
      sub_cat_has_products: false,
      cat_has_subcategories: false,
      showDescriptionForId: null,
      showDescriptionForType: null,
      showProductsForId: null,
      showProductsForType: null
    };

    setSelectedServices(updated);
    setSubCategories([]);
    setProducts([]);
  };

  const handleCategorySelect = async (personIndex, categoryObj) => {
    const updated = [...selectedServices];
    updated[personIndex].category = categoryObj;
    updated[personIndex].subCategory = null;
    updated[personIndex].product = null;
    updated[personIndex].cat_has_products = true;
    updated[personIndex].sub_categories = [];
    updated[personIndex].products = [];
    updated[personIndex].showDescriptionForId = null;
    updated[personIndex].showDescriptionForType = null;

    // Fetch Products
    const products = await fetchProducts(categoryObj.id);
    if (products.length > 0) {
      updated[personIndex].showProductsForType = "category";
      updated[personIndex].showProductsForId = categoryObj.id;
      updated[personIndex].products = products;
      updated[personIndex].cat_has_products = true;
    } else {
      updated[personIndex].showProductsForType = null;
      updated[personIndex].showProductsForId = null;
      updated[personIndex].products = [];
      updated[personIndex].cat_has_products = false;
    }

    // Fetch Subcategories with `is_hidden=false`
    const subcats = await CategoryService.index(
      { parent_id: categoryObj.id, is_hidden: false, is_active: 1, with: 'media' },
      axiosPrivate,
      auth?.accessToken ? false : true
    );

    if (subcats.length > 0) {
      updated[personIndex].cat_has_subcategories = true;
      updated[personIndex].sub_categories = subcats;
    } else {
      updated[personIndex].cat_has_subcategories = false;
      updated[personIndex].sub_categories = [];
    }

    setSelectedServices(updated);
  };


  // When select a subcategory
  const handleSubCategorySelect = async (personIndex, subcatObj) => {
    const updated = [...selectedServices];
    updated[personIndex].subCategory = subcatObj;
    updated[personIndex].product = null;
    updated[personIndex].sub_cat_has_products = true;
    updated[personIndex].showDescriptionForId = null;
    updated[personIndex].showDescriptionForType = null;

    // Fetch Products under the selected subcategory
    const products = await fetchProducts(subcatObj.id);
    if (products.length > 0) {
      updated[personIndex].showProductsForType = "subCategory";
      updated[personIndex].showProductsForId = subcatObj.id;
      updated[personIndex].products = products;
    } else {
      updated[personIndex].sub_cat_has_products = false;
      updated[personIndex].showProductsForType = null;
      updated[personIndex].showProductsForId = null;
      updated[personIndex].products = [];
    }

    setSelectedServices(updated);
  };

  const handleProductSelect = (personIndex, prodObj) => {
    const updated = [...selectedServices];
    updated[personIndex].product = prodObj;
    setSelectedServices(updated);
  };

  // Add or remove person
  const addPerson = () => {
    if (selectedServices.length < 4) {
      setSelectedServices([
        ...selectedServices,
        { domain: null, category: null, subCategory: null, product: null, cat_has_products: false, categories: [], sub_categories: [], products: [], sub_cat_has_products: false, cat_has_subcategories: false, showDescriptionForId: null, showDescriptionForType: null, showProductsForId: null, showProductsForType: null }
      ]);
    }
  };
  const removePerson = (idx) => {
    setSelectedServices(selectedServices.filter((_, i) => i !== idx));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const anyEmpty = selectedServices.some(s => !s.product);
    if (anyEmpty) {
      notify.warn('Merci de sélectionner un produit');
      return;
    }

    const address = updatedState?.address || JSON.parse(sessionStorage.getItem('state') || '{}')?.address;
    const stateToSave = { selectedServices, duo: selectedServices.length === 2 && isActive, address, phone };
    sessionStorage.setItem('state', JSON.stringify(stateToSave));

    if (selectedServices.length === 2 && !isActive && !hasModalShown) {
      openModalservice();
      setHasModalShown(true);
      return false;
    }

    navigate('/choose-therapist', { state: stateToSave });
  };

  const toggleDescription = (type, id, personIndex) => {
    const updated = [...selectedServices];
    updated[personIndex].showProductsForType = null;
    updated[personIndex].showProductsForId = null;
    updated[personIndex].showDescriptionForId = id;
    updated[personIndex].showDescriptionForType = type;
    if (type === "category") {
      updated[personIndex].category = null;
    }
    else if (type === "subCategory") {
      updated[personIndex].subCategory = null;
    }
    updated[personIndex].product = null;
    setSelectedServices(updated);
  };
  return (
    <>
      <MenuHeader />
      <Nav page="service" />
      <div className={`container container-location container-service row-${selectedServices.length}`}>
        <h2>Choisissez le type de soin</h2>
        <form className='service-form' onSubmit={handleSubmit}>
          <div className='content-row'>
            {selectedServices.map((person, idx) => (
              <div key={idx} className="person-services">
                {selectedServices.length > 1 && (
                  <h3 className='person-title'>
                    <span>
                      <img className='people' src={people} alt="icon" />
                      {idx === 0 ? "Moi" : `Invité(e) ${idx}`}
                    </span>
                    {idx > 0 && (
                      <button
                        type='button'
                        className='person-remove'
                        onClick={() => removePerson(idx)}
                      >
                        <img className='remove' src={remove} alt="Supprimer" />
                      </button>
                    )}
                  </h3>
                )}

                {/* show domain */}
                {(!person.domain || (person.domain && !person.categories.length)) && (
                  <div className="services-container">
                    {domains.map(dom => {
                      const isSelected = person.domain?.id === dom.id;
                      return (

                        <div key={dom.id} className="service-wrapper">
                          <div
                            className={`service-card ${isSelected ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url(${dom?.media[0]?.original_url}) no-repeat center center / cover`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{dom.title}</h3>
                                {dom.description !== null && dom.description !== '' && (
                                  <ThemeProvider theme={theme}>
                                    <Button variant="text" color='white' onClick={() => toggleDescription("domain", dom.id, idx)} sx={{ opacity: 0.7, gap: '5px', padding: 0 }}>Plus d'infos <img src="assets/images/icon-expand.svg" /></Button>
                                  </ThemeProvider>
                                )}
                              </div>
                              {isSelected ? (
                                <IconButton onClick={() => handleDomainSelect(idx, dom)}>
                                  <img src='assets/images/check.png' height={20} width={20} />
                                </IconButton>
                              ) : (
                                <ThemeProvider theme={theme}>
                                  <Button variant="outlined" color='white' onClick={() => handleDomainSelect(idx, dom)} sx={{ borderRadius: '15px' }}>Sélectionner</Button>
                                </ThemeProvider>
                              )}
                            </div>
                          </div>
                          {person.showDescriptionForType === "domain" && person.showDescriptionForId === dom.id && <div className="service-description">{dom.description}</div>}
                        </div>
                      )
                    })}
                  </div>
                )}

                {/* show category */}
                <div className="services-container">
                  {person.categories?.length > 0 && person.categories.map(cat => {
                    const isSelected = person.category?.id === cat.id;
                    return person.domain && (!person.category || (person.category && !person.sub_categories.length) || (person.category && person.cat_has_products && (!person.cat_has_subcategories || (person.cat_has_subcategories && person.category.id === cat.id)))) && (
                      <div key={cat.id} className="service-wrapper">
                        <div
                          className={`service-card ${isSelected ? 'selected' : ''}`}
                          style={{
                            background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${cat?.media[0]?.original_url}) no-repeat center center / cover`,
                          }}
                        >
                          <div className="service-info">
                            <div className="service-text">
                              <h3>{cat.title}</h3>
                              {cat.description !== null && cat.description !== '' && (
                                <ThemeProvider theme={theme}>
                                  <Button variant="text" color='white' onClick={() => toggleDescription("category", cat.id, idx)} sx={{ opacity: 0.7, gap: '5px', padding: 0 }}>Plus d'infos <img src="assets/images/icon-expand.svg" /></Button>
                                </ThemeProvider>
                              )}
                            </div>
                            {isSelected ? (
                              <IconButton onClick={() => () => handleCategorySelect(idx, cat)}>
                                <img src='assets/images/check.png' height={20} width={20} />
                              </IconButton>
                            ) : (
                              <ThemeProvider theme={theme}>
                                <Button variant="outlined" color='white' onClick={() => handleCategorySelect(idx, cat)} sx={{ borderRadius: '15px' }}>Sélectionner</Button>
                              </ThemeProvider>
                            )}
                          </div>
                        </div>
                        {person.showDescriptionForType === "category" && person.showDescriptionForId === cat.id && <div className="service-description">{cat.description}</div>}
                        {person.showProductsForType === "category" && person.showProductsForId === cat.id && <div className='form-duree'>
                          <div className="form-group">
                            <div className="btn-group group-3" role="group">
                              {person.products.map(product => (
                                <button
                                  type="button"
                                  key={product.id}
                                  className={`btn-default ${selectedServices[idx]?.product?.id === product.id ? 'btn-checked' : 'btn-unchecked'}`}
                                  onClick={() => handleProductSelect(idx, product)}
                                >
                                  <span>{product.duration} min</span>
                                  <br />
                                  <span className='price'>{product.price} chf</span>
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                        }
                      </div>
                    );
                  })}
                </div>

                {/* show subCategories */}
                {person.domain && person.category && person.sub_categories.length > 0 && (!person.subCategory || (person.subCategory && !person.products.length) || (person.subCategory && person.sub_cat_has_products)) && (
                  <div className="services-container">
                    {person.sub_categories.map(sc => {
                      const isSelected = person.subCategory?.id === sc.id;
                      return (
                        <div key={sc.id} className="service-wrapper">
                          <div
                            className={`service-card ${isSelected ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0,0,0,0.8), rgba(0,0,0,0.5)), url(${sc?.media[0]?.original_url}) center/cover no-repeat`
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{sc.title}</h3>
                                {sc.description !== null && sc.description !== '' && (
                                  <ThemeProvider theme={theme}>
                                    <Button variant="text" color='white' onClick={() => toggleDescription("subCategory", sc.id, idx)} sx={{ opacity: 0.7, gap: '5px', padding: 0 }}>Plus d'infos <img src="assets/images/icon-expand.svg" /></Button>
                                  </ThemeProvider>
                                )}
                              </div>
                              {isSelected ? (
                                <IconButton onClick={() => () => handleSubCategorySelect(idx, sc)}>
                                  <img src='assets/images/check.png' height={20} width={20} />
                                </IconButton>
                              ) : (
                                <ThemeProvider theme={theme}>
                                  <Button variant="outlined" color='white' onClick={() => handleSubCategorySelect(idx, sc)} sx={{ borderRadius: '15px' }}>Sélectionner</Button>
                                </ThemeProvider>
                              )}
                            </div>
                          </div>
                          {person.showDescriptionForType === "subCategory" && person.showDescriptionForId === sc.id && <div className="service-description">{sc.description}</div>}
                          {person.showProductsForType === "subCategory" && person.showProductsForId === sc.id && <div className='form-duree'>
                            <div className="form-group">
                              <div className="btn-group group-3" role="group">
                                {person.products.map(product => (
                                  <button
                                    type="button"
                                    key={product.id}
                                    className={`btn-default ${selectedServices[idx]?.product?.id === product.id ? 'btn-checked' : 'btn-unchecked'}`}
                                    onClick={() => handleProductSelect(idx, product)}
                                  >
                                    <span>{product.duration} min</span>
                                    <br />
                                    <span className='price'>{product.price} chf</span>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}

          </div>
          {selectedServices.length === 2 && (
            <div className='duo'>
              <div className='info'>
                Massage DUO <i className="fa fa-info-circle"></i>
              </div>
              <div className='toggle'>
                <div
                  className={`toggle-switch ${isActive ? 'active' : ''}`}
                  onClick={toggleSwitch}
                />
              </div>
            </div>
          )}
          <Container sx={{ display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap', padding: '0 !important' }}>
            <ThemeProvider theme={theme}>
              <Button variant="outlined" color='dark' type="button"
                onClick={addPerson} sx={{ textTransform: 'none', borderRadius: '15px', width: '100%', maxWidth: '315px', minHeight: '57px' }}>Ajouter une personne</Button>
              <Button variant="contained" color='dark_yellow' type="submit" sx={{ textTransform: 'none', borderRadius: '15px', width: '100%', maxWidth: '315px', minHeight: '57px' }}>Suivant</Button>
            </ThemeProvider>
          </Container>
        </form >
      </div >
      <Modal
        isOpen={modalIsOpenService}
        onRequestClose={closeModalservice}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="zone-not-served-container">
          <div className="popup-box">
            <button onClick={closeModalservice} className="close-btn"><img src={croix} alt="Fermer" /></button>
            <p> Merci de choisir si vous souhaitez un massage DUO avec 2 flyPRO, ou bien 2 massages à la suite </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChooseService;