// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import MenuHeader from './MenuHeader';
import { Box, Button, createTheme, IconButton, ThemeProvider } from '@mui/material';
import useAuth from '../hooks/useAuth';
import AuthService from '../services/auth.service';
import PaymentService from '../services/payment.service';
import { useLoading } from '../context/LoadingProvider';
import { useNotifications } from '../context/NotificationProvider';

const PaymentCards = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(57, 61, 68, 1)'
      },
      secondary: {
        main: 'rgba(250, 239, 155, 1)'
      }
    }
  });
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const loader = useLoading();
  const notify = useNotifications();

  const [cards, setCards] = useState([]);

  const fetchCards = async () => {
    try {
      const response = await AuthService.me({ id: auth.user.id, with: 'cards.brand' }, axiosPrivate);
      setCards(response.user.cards);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    if (auth.user) {
      fetchCards();
      checkSuccessParaminUrl();
    }
  }, [auth.user]);

  const addCard = async () => {
    try {
      const response = await PaymentService.createTokenTransaction(axiosPrivate);
      console.log({ response })

      // const script = document.createElement('script');
      // script.src = response.data.payment_iframe;
      // script.async = true;
      // script.onload = () => {
      //   window.IframeCheckoutHandler.configure('replacePrimaryAction', true);
      //   const iframe = window.IframeCheckoutHandler(343859);
      //   iframe.create('wallee-iframe');
      // };
      // document.body.appendChild(script);
      // loader.stop();
      loadIframe(response.data.payment_iframe, response.data.payment_url);
      // loadLightbox(response.data.payment_lightbox, response.data.payment_url);
      // loadUrl(response.data.payment_url);
    } catch (err) {
      loader.stop();
      console.error(err);
    }
  }
  const loadUrl = (paymentUrl) => {
    window.location.replace(paymentUrl);
  }
  const loadLightbox = (paymentLightboxScript, paymentUrl) => {
    loader.start();
    const script = document.createElement('script');
    script.src = paymentLightboxScript;
    script.async = true;
    script.onload = () => {
      window.LightboxCheckoutHandler.startPayment(343859, () => { loader.stop(); loadUrl(paymentUrl) });
      loader.stop();
    };
    document.body.appendChild(script);
  }
  const [handler, setHandler] = useState(null);

  const loadIframe = (paymentIframeScript, paymentUrl) => {
    loader.start();
    const script = document.createElement('script');
    script.src = paymentIframeScript;
    script.async = true;
    script.onload = () => {
      window.IframeCheckoutHandler.configure('replacePrimaryAction', true);
      setHandler(window.IframeCheckoutHandler(343859));
      loader.stop();
    };
    document.body.appendChild(script);
  }


  useEffect(() => {
    if (handler) {
      handler.setValidationCallback(
        function (validationResult) {
          // Reset payment errors
          loader.start();
          console.log({ validationResult });
          if (validationResult.success) {
            loader.stop();
            handler.submit();
            // setHandler(null);
            // Create the order within the shop and eventually update the transaction.
          } else {
            // Display errors to customer
            loader.stop();
            setHandler(null);

            validationResult.errors.forEach(error => {
              notify.error(error);
            });

            // $.each(validationResult.errors, function (index, errorMessage) {
            //   $('#payment-errors').append('<li>' + errorMessage + '</li>');
            // });
          }
        });
      handler.create('wallee-iframe');

    }
  }, [handler]);

  const confirmAddCard = () => {
    if (handler) {
      handler.validate();
    }
  }
  const cancel = async () => {
    await fetchCards();
    setHandler(null);
  }
  const deleteCard = async (token) => {
    try {
      await PaymentService.deleteCard(token, axiosPrivate);
      fetchCards();
    } catch (err) {
      console.error(err);
    }
  }

  const checkSuccessParaminUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('success') && urlParams.has('transactionId')) {
      await fetchCards();
      notify.success('Carte ajoutée avec succès');
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }

  return (
    <>
      <MenuHeader />

      <div className="container container-payment-card">
        <h2>Carte de paiement</h2>
        <div className='cards'>
          {
            handler === null && cards?.map((card, index) => (
              <div key={index} className="payment-card">
                <div className="flex-column gap-10">
                  <div className="flex-row space-between align-center">
                    <img className='brand-icon' src={card.brand.logo} alt='card-brand' width={45} />
                    <div className='flex-row gap-20'>
                      <IconButton onClick={() => deleteCard(card.token)}><img src='assets/images/icon-trash.svg' alt='delete' /></IconButton>
                    </div>
                  </div>
                  <div className='flex-column'>
                    <span className='card-holder'>{card.card_holder}</span>
                    <span className='hashed'>{card.hashed.replaceAll('X', '•')}</span>
                    <span className='expiry'>Expire {new Date(card.expiry).toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' })}</span>
                  </div>
                </div>
              </div>
            ))
          }
          {handler !== null && (
            <div id='wallee-iframe'></div>
          )}
        </div>

        <ThemeProvider theme={theme}>
          {
            handler === null && (
              <Button
                onClick={addCard}
                variant='outlined'
                sx={{ width: '100%', maxWidth: '214px', margin: '0 auto', borderRadius: '15px', border: '2px solid rgba(57, 61, 68, 1)', alignItems: 'center', fontFamily: 'Lato !important', gap: '10px', textTransform: 'none', lineHeight: 1, padding: '15px 20px' }}
              >
                <svg width="16" height="17" viewBox="0 0 16 17" fill="#393D44" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.85266C0 4.44641 3.5625 0.852661 8 0.852661C12.4062 0.852661 16 4.44641 16 8.85266C16 13.2902 12.4062 16.8527 8 16.8527C3.5625 16.8527 0 13.2902 0 8.85266ZM8 12.3527C8.40625 12.3527 8.75 12.0402 8.75 11.6027V9.60266H10.75C11.1562 9.60266 11.5 9.29016 11.5 8.85266C11.5 8.44641 11.1562 8.10266 10.75 8.10266H8.75V6.10266C8.75 5.69641 8.40625 5.35266 8 5.35266C7.5625 5.35266 7.25 5.69641 7.25 6.10266V8.10266H5.25C4.8125 8.10266 4.5 8.44641 4.5 8.85266C4.5 9.29016 4.8125 9.60266 5.25 9.60266H7.25V11.6027C7.25 12.0402 7.5625 12.3527 8 12.3527Z" />
                </svg>
                Ajouter une carte
              </Button>
            )
          }
          {handler !== null && (
            <Box sx={{ margin: '0 auto' }}>
              <Button
                onClick={confirmAddCard}
                variant='contained'
                sx={{ width: '100%', maxWidth: '214px', margin: '0 auto', borderRadius: '15px', alignItems: 'center', fontFamily: 'Lato !important', gap: '10px', textTransform: 'none', lineHeight: 1, padding: '15px 20px', color: 'rgba(250, 239, 155, 1)' }}
              >
                <svg width="16" height="17" viewBox="0 0 16 17" fill="rgba(250, 239, 155, 1)" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.85266C0 4.44641 3.5625 0.852661 8 0.852661C12.4062 0.852661 16 4.44641 16 8.85266C16 13.2902 12.4062 16.8527 8 16.8527C3.5625 16.8527 0 13.2902 0 8.85266ZM8 12.3527C8.40625 12.3527 8.75 12.0402 8.75 11.6027V9.60266H10.75C11.1562 9.60266 11.5 9.29016 11.5 8.85266C11.5 8.44641 11.1562 8.10266 10.75 8.10266H8.75V6.10266C8.75 5.69641 8.40625 5.35266 8 5.35266C7.5625 5.35266 7.25 5.69641 7.25 6.10266V8.10266H5.25C4.8125 8.10266 4.5 8.44641 4.5 8.85266C4.5 9.29016 4.8125 9.60266 5.25 9.60266H7.25V11.6027C7.25 12.0402 7.5625 12.3527 8 12.3527Z" />
                </svg>
                Add card
              </Button>
              <Button
                onClick={cancel}
                variant='text'
                sx={{ width: '100%', maxWidth: '214px', margin: '0 auto', borderRadius: '15px', alignItems: 'center', fontFamily: 'Lato !important', gap: '10px', textTransform: 'none', lineHeight: 1, padding: '15px 20px' }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </ThemeProvider>

      </div>
    </>
  );
}

export default PaymentCards;
