import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import PromotionService from '../services/promotion.service';
import ProductsService from '../services/product.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

const Treatment = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [showAddressField, setShowAddressField] = useState(false);
    const [products, setProducts] = useState([]);
    const [treatmentDetails, setTreatmentDetails] = useState({
        product: '',
        duration: '30min',
        validity: '1 year',
        deliveryAddress: '',
        promoCode: '',
        client_id: auth?.user?.id,
        amount: '',
    });

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'product') {
            const selectedProduct = products.find((product) => product.id.toString() === value);

            console.log({ selectedProduct })

            setTreatmentDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
                amount: selectedProduct ? selectedProduct.price : '',
            }));
        } else {
            setTreatmentDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };


    const fetchProducts = async () => {
        try {
            const response = await ProductsService.index({ with: 'category', is_active: 1 }, axiosPrivate);
            console.log({ response })
            setProducts(response);
        } catch (error) {

        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Generate the promo code dynamically
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let promoCode = '';
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            promoCode += characters.charAt(randomIndex);
        }
        setTreatmentDetails((prevDetails) => ({
            ...prevDetails,
            promoCode,
        }));

        const creationDate = new Date();
        const validityPeriod = treatmentDetails.validity === '1 year' ? 1 : parseInt(treatmentDetails.validity, 10);
        const validityDate = new Date(creationDate.setFullYear(creationDate.getFullYear() + validityPeriod));

        const payload = {
            name: 'Treatment Purchase',
            code: promoCode,
            date_creation: new Date().toISOString().split('T')[0],
            date_validity: validityDate.toISOString().split('T')[0],
            type: 'FLATT_OFF',
            amount: treatmentDetails.amount,
            assigned_to: 'All',
            total_usage: 1,
            usage_per_customer: 1,
            notifyClients: false,
            is_active: 1,
            client_id: treatmentDetails.client_id,
            product_id: treatmentDetails.product,
        };

        try {
            await PromotionService.store(payload, axiosPrivate);
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>Acheter un traitement</h2>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="massage">Acheter un traitement de</label>
                        <select
                            id="massage"
                            name="product"
                            className="form-control"
                            value={treatmentDetails.product}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled>
                                Sélectionnez un produit
                            </option>
                            {products.length > 0 ? (
                                products.map((product) => (
                                    <option key={product.id} value={product.id}>
                                        {product.category?.title} / {product.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>
                                    Aucun produit disponible
                                </option>
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="price">Prix du produit</label>
                        <input
                            type="text"
                            id="price"
                            name="amount"
                            className="form-control"
                            value={treatmentDetails.amount}
                            onChange={handleInputChange}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="validity">Validité</label>
                        <select
                            id="validity"
                            name="validity"
                            className="form-control"
                            value={treatmentDetails.validity}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="1 year">1 an</option>
                            <option value="2 years">2 ans</option>
                        </select>
                    </div>
                    <div
                        className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : '' }}
                    >
                        <div
                            className="text-wrapper"
                            style={{ color: showAddressField ? '#393D44' : '' }}
                        >
                            Carte cadeau physique
                        </div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="deliveryAddress">Adresse de livraison</label>
                            <input
                                type="text"
                                id="deliveryAddress"
                                name="deliveryAddress"
                                className="form-control"
                                placeholder="Adresse"
                                value={treatmentDetails.deliveryAddress}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )}
                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>
        </>
    );
};

export default Treatment;
