class AuthService {
    static base_url = process.env.REACT_APP_BACKEND_URL + '/api/auth';

    static buildParams(params) {
        return params ? new URLSearchParams(params).toString() : '';
    }

    static async login(credentials, axiosInstance) {
        const response = await axiosInstance.post(`${this.base_url}/login`, credentials);
        return response.data;
    }

    static async socialLogin(credentials, axiosInstance, provider) {
        const response = await axiosInstance.post(`${this.base_url}/login/${provider}/callback`, credentials);
        return response.data;
    }

    static async me(params, axiosInstance) {
        const queryString = this.buildParams(params);
        const response = await axiosInstance.get(`${this.base_url}/me?${queryString}`);
        return response.data;
    }

    static async logout(axiosInstance) {
        const response = await axiosInstance.post(`${this.base_url}/logout`);
        return response.data;
    }
}

export default AuthService;