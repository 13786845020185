import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notification context to handle success and error messages
const NotificationContext = createContext();


export const useNotifications = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {

    const success = (message) => toast.success(message);
    const error = (message) => toast.error(message);
    const info = (message) => toast.info(message);
    const warn = (message) => toast.warn(message);
    const dismissAll = () =>  toast.dismiss();

    return (
        <NotificationContext.Provider value={{ success, error, info, warn, dismissAll }}>
            <ToastContainer autoClose={5000} draggable stacked/>
            {children}
        </NotificationContext.Provider>
    );
};
