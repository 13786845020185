import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import useAxiosPrivate from '../hooks/useAxiosPrivate'; 
import { endpoints } from '../utils/utils'; 
import AddressService from '../services/address.service';
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const EditAdresse = () => {
  const [formData, setFormData] = useState({
    id: '',
    street: '',
    zip_code: '',
    city: '',
    street_number: '',
    state: '',
    state_code: '',
    country: '',
    country_code: '',
    lat: null,
    lng: null,
  });

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
    libraries,
  });

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getProfil); 
        setFormData({
          id: response.data.user?.primary_address?.id || '',
          street: response.data.user?.primary_address?.street || '',
          zip_code: response.data.user?.primary_address?.zip_code || '',
          city: response.data.user?.primary_address?.city || '',
        });
      } catch (err) {
        console.error('Erreur lors de la récupération de l\'adresse :', err);
      }
    };

    fetchAddress();
  }, [axiosPrivate]);

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: '',
      country_code: '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    console.log("places", addressComponents)
    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          newAddress.state = component.long_name;
          newAddress.state_code = component.short_name;
          break;
        case 'country':
          newAddress.country = component.long_name;
          newAddress.country_code = component.short_name;
          break;
        /* case 'street_number':
          newAddress.street_number = component.long_name;
          break; */
        default:
          break;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      ...newAddress,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AddressService.update(formData.id, formData, axiosPrivate);
      navigate('/profile');
    } catch (err) {
      console.error('Erreur lors de la mise à jour de l\'adresse :', err);
    }
  };

  if (loadError) return <div>Erreur de chargement des cartes</div>;
  if (!isLoaded) return <div>Chargement des cartes...</div>;

  return (
    <>
      <Header />

      <div className="container container-profile">
        <div className="name">Modifier adresse</div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Adresse</label>
            <Autocomplete
              apiKey="YOUR_GOOGLE_MAPS_API_KEY"
              onPlaceSelected={handlePlaceSelect}
              options={{ types: ['address'], componentRestrictions: { country: 'CH' } }}
              placeholder="Saisissez une adresse"
              style={{ width: '100%', padding: '0 15px', height: '40px' }}
            />
          </div>
          <div className="form-group">
            <label>Rue</label>
            <input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Code postal</label>
            <input
              type="text"
              name="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Ville</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-submit">
            <input type="submit" value="Enregistrer" />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAdresse;
