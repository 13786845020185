import React, { useEffect, useState } from "react";
import Valide from '../assets/images/valide.svg';
import { Link, useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import { Button, createTheme, ThemeProvider } from "@mui/material";

const ConfirmationPage = () => {
    const theme = createTheme({
        palette: {
            dark: {
                "main": "#393D44",
                "light": "#393D44",
                "dark": "#393D44",
                "contrastText": "#FFFFFF",
            },
        },
    });
    const [success, setSuccess] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkPaymentType();
        checkSuccessParaminUrl();
    }, []);
    const checkSuccessParaminUrl = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('success')) {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
        window.history.replaceState({}, document.title, window.location.pathname);
    };

    const checkPaymentType = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const bookingId = urlParams.get('booking_id');
        
        if (bookingId) {
            setIsBooking(true);
        } else {
            setIsBooking(false);
        }
    };

    return (
        <>
            <MenuHeader />
            {success === true && (
                <div className="content-wrap">
                    <div className="list-item-with-image">
                        <div className="title">
                            <img
                                className="https-app"
                                alt="Https app"
                                src={Valide}
                            />

                            <div className="text-wrapper">Merci!</div>

                            <p className="div">
                                Vous avez reçu un email avec votre confirmation et votre reçu.
                            </p>
                        </div>
                    </div>

                    <p className="merci-de-votre">
                        <span className="span">
                            Merci de votre confiance.
                            <br />
                        </span>

                        <span className="text-wrapper-2">First Love Yourself</span>
                    </p>

                    {isBooking && (
                        <div className="buttons">
                            <button className="button">
                                <Link to="/commande">
                                    <div className="book-a-treatment">Voir mes commandes</div>
                                </Link>
                            </button>
                        </div>
                    )}
                </div>
            )}

            {success === false && (
                <div className="content-wrap">
                    <div className="list-item-with-image">
                        <div className="title">
                            <img
                                className="https-app"
                                alt="Https app"
                                src='assets/images/icon-failed.svg'
                            />

                            <div className="text-wrapper">Oops!</div>

                            <p className="div">
                                Votre paiement n'a pas abouti.
                            </p>
                        </div>
                    </div>

                    <p className="merci-de-votre">
                        <span className="span">
                            Merci de votre confiance.
                            <br />
                        </span>

                        <span className="text-wrapper-2">First Love Yourself</span>
                    </p>

                    <div className="buttons">
                        <button className="button">
                            <ThemeProvider theme={theme}><Button color="dark" variant='contained' onClick={() => navigate(-1)}>Retour</Button></ThemeProvider>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};


export default ConfirmationPage;