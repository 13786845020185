// ChooseLocation.js
import React from 'react';
import MenuHeader from './MenuHeader'

const Contact = () => {




  return (
    <>
    <MenuHeader />

    <div className="container container-contact">
      <h2>Appelez-nous</h2>
      <form>
        <div className="form-tel">
          <input type="text" placeholder="+41 123 4555 6334" />
        </div>
        <div className="form-text">
          <label>Adresse email</label>
          <input type="email" name="" id="" />
        </div>
        <div className="form-textarea">
          <label>Message</label>
          <textarea name="" id="" placeholder="Écrivez votre message"></textarea>
        </div>
        <div className="form-submit">
          <input type="submit" value="Envoyer" />
        </div>
      </form>
    </div>
    </>
  );
}

export default Contact;
