class PaymentService {
    static base_url = process.env.REACT_APP_BACKEND_URL + '/api';

    static buildParams(params) {
        return params ? new URLSearchParams(params).toString() : '';
    }

    static async createTokenTransaction(axiosInstance) {
        const response = await axiosInstance.post(`${this.base_url}/payments/token`);
        return response;
    }

    static async deleteCard(token, axiosInstance) {
        const response = await axiosInstance.delete(`${this.base_url}/payments/token/${token}`);
        return response;
    }
}

export default PaymentService;