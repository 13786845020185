import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import NavHotel from './NavHotel';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ProductService from '../services/product.service';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';
import remove from '../assets/images/remove.png';
import people from '../assets/images/people.png';

function HotelChooseService() {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([{ service: null, productId: null, show: false, show_description: false, product: null }]); // État pour les choix de soin par personne
  const [expandedService, setExpandedService] = useState([{ service: null, productId: null }]); // État pour les choix de soin par personne
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const notify = useNotifications();
  const { auth } = useAuth();
  const loc = useLocation();
  const hotelId = loc.state?.hotelId;
  const hotel = loc.state?.hotel;
  const duo_capability = loc.state?.duo_capability;

  const [isActive, setIsActive] = useState(false);

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  const handleServiceSelect = (index, service, show_description) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].service = service;
    updatedServices[index].show_description = show_description;
    updatedServices[index].product = service?.products?.[0];;
    updatedServices[index].productId = service?.products?.[0]?.id;
    setSelectedServices(updatedServices);
  };

  const addPerson = () => {
    if (selectedServices.length < 4) {
      setSelectedServices([...selectedServices, { service: null, show: false, show_description: false, productId: null, product: null }]);
    }
  };
  const removePerson = (index) => {
    setSelectedServices(selectedServices.filter((_, i) => i !== index));
  };

  const fetchProducts = async () => {
    console.log("auth", auth)
    try {
      const productResponse = await ProductService.index({ with: 'media,hotel', hotel_id: hotelId, is_active: 1 }, axiosPrivate, auth?.accessToken ? false : true);
      console.log({ productResponse })
      setServices(productResponse);
    } catch (error) {

    }
  };

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem('hotelChooseServiceState') ?? '{}');

    // Reset selectedServices if no saved state
    if (!savedState.selectedServices) {
      setSelectedServices([{ service: null, productId: null, show: false, show_description: false, product: null }]);
    } else {
      setSelectedServices(savedState.selectedServices);
    }

    if (savedState.isActive !== undefined) {
      setIsActive(savedState.isActive);
    }

    fetchProducts();
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const allProductsNull = selectedServices.some(service => service.product === null);

    if (allProductsNull) {
      notify.warn('Merci de selectionner un produit');
      return;
    }
    sessionStorage.setItem(
      'hotelChooseServiceState',
      JSON.stringify({ selectedServices, isActive })
    );
    navigate('/hotel-choose-therapist', {
      state: {
        hotel_address: hotel?.address, hotelId: hotelId, selectedServices: selectedServices.map(service => ({
          service: service.service,
        })), duo: selectedServices.length === 2 && isActive
      }
    });
  };

  return (
    <>
      <MenuHeader />
      <NavHotel page="service" />
      <div className={`container container-location row-${selectedServices.length}`}>
        <h2>Choisissez le type de soin</h2>
        <form onSubmit={handleSubmit}>
          <div className='content-row'>
            {selectedServices?.map((selectedService, index) => (
              <div key={index} className="person-services">
                {selectedServices.length > 1 && (
                  <h3 className='person-title'>

                    <span><img className='people' src={people} alt="Supprimer" />{index === 0 ? "Moi" : "Invité(e) " + (index)}</span>
                    {index > 0 && (
                      <button type='button' className='person-remove' onClick={() => removePerson(index)}>
                        <img className='remove' src={remove} alt="Supprimer" />
                      </button>
                    )}
                  </h3>
                )}
                <div key={index} className="services-container">
                  {(
                    services.map(service => {
                      return (
                        <div key={service.id} className="service-wrapper">
                          <div
                            className={`service-card ${selectedService.service?.id === service.id && !selectedService.show_description ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${service?.media[0]?.original_url}) no-repeat center center / cover`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{service.name}</h3>
                                <a onClick={() => handleServiceSelect(index, service, true)} className="more-info">Plus d'infos &gt;</a>
                              </div>
                              <button
                                type="button"
                                className="select-button"
                                onClick={() => handleServiceSelect(index, service, false)}
                              >
                                Sélectionner
                              </button>
                              <button
                                type="button"
                                className={`btn-outline-primary ${selectedService.product ? 'selected' : 'selected'}`}
                              >
                                &gt;
                              </button>
                            </div>
                          </div>
                          {(selectedService.service?.id === service.id && selectedService.show_description === true) && (
                            <div className='service-description'>
                              {service?.description}
                            </div>
                          )}

                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            ))}

          </div>
          {duo_capability && selectedServices.length === 2 && (
            <div className='btn-grp'>
              <div className='duo'>
                <div className='info'>
                  Massage DUO <i className="fa fa-info-circle"></i>
                </div>
                <div className='toggle'>
                  <div className={`toggle-switch ${isActive ? 'active' : ''}`} onClick={toggleSwitch}></div>
                </div>
              </div>
            </div>
          )}


          <div className='btn-grp'>
            {(duo_capability &&
              <button type="button" onClick={addPerson} className="btn-add mt-4" disabled={selectedServices.length >= 4}>
                Ajouter une personne
              </button>
            )}
            <button type="submit" className="btn-next mt-4">
              Suivant
            </button>
          </div>

        </form>
      </div>
    </>
  );
}

export default HotelChooseService;

