import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { useLocation } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import star2 from '../assets/images/star2.svg';
import pencil from '../assets/images/pencil.svg';
import questionmark from '../assets/images/questionmark.svg';
import therapeut1 from '../assets/images/therapeut1.png';
import BookingService from '../services/booking.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import MenuHeader from './MenuHeader';
import PromotionService from "../services/promotion.service";
import PromotionUsageService from "../services/promotion_usage.service";
import Header from './Header';
import { useLoading } from '../context/LoadingProvider';

// const stripePromise = loadStripe('pk_test_51IYwAqDh1V63ycuimBrVJgxaQkDKtUdxkpFmYv38yV8qmiCEeGCKTvlQi7cIAjepUBVaY7Tyx4xSAw8qjBdbB1ze00OanEXi4X'); // Remplacez par votre clé publique Stripe

function PaymentForm({ totalPriceCHF }) {
  // const stripe = useStripe();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { address, duo, totalDuration, totalPrice, selectedServices, selectedTherapist, selectedDate, selectedTime, phone } = location.state || JSON.parse(localStorage.getItem('state') ?? '{}');
  const { lng, lat, zip_code, street, street_number, state, state_code, country, country_code } = address || {};
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (phone) {
      setPhoneNumber(phone);
    }
  }, []);
  // console.log('totalDuration', totalDuration)
  // console.log('totalPrice', totalPrice)
  useEffect(() => {
    if (!selectedServices || selectedServices.length === 0) {
      navigate('/choose-service');
    } else if (!address) {
      navigate('/choose-address');
    }
    if (!selectedTherapist || selectedTherapist.length === 0) {
      navigate('/choose-therapist');
    }
  }, [selectedServices, selectedTherapist, address, navigate]);


  const [message, setMessage] = useState('');
  const [promotions, setPromotions] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(totalPrice);
  const [alertMessage, setAlertMessage] = useState('');
  /*  const [termsAccepted, setTermsAccepted] = useState(false);
   const [paymentError, setPaymentError] = useState(null);
   const [paymentProcessing, setPaymentProcessing] = useState(false);
   const [formData, setFormData] = useState({
     description: '',
   }) */
  const handleSave = () => {
    setShowInput(false);
  };

  const fetchPromotions = async () => {
    try {
      const today = new Date().toISOString().split("T")[0];
      const response = await PromotionService.index(
        {
          code: promoCode,
          date_validity: `gte:${today}`,
          is_active: 1,
          with: 'usage',
        },
        axiosPrivate
      );
      // console.log("Promotion:", response[0]);
      if (response?.length > 0) {
        const promotion = response[0];
        if (promotion.extra?.zones_ranges?.length > 0) {
          const zipCodeInt = parseInt(zip_code, 10);
          const isZipCodeValid = promotion.extra.zones_ranges.some(zone => {
            const zoneInt = typeof zone === 'string' ? parseInt(zone, 10) : zone;
            return zoneInt === zipCodeInt;
          });
          if (isZipCodeValid) {
            console.log('Valid zip code found:', zip_code);
          } else {
            setAlertMessage("Invalid promo code - Zone restriction");
            return;
          }
        }
        if (promotion.all_clients) {
          // console.log("Promotion:", response[0]);
          return response[0];
        } else {
          const clientResponse = await PromotionService.index(
            {
              id: promotion.id,
              with: 'clients,usage',
            },
            axiosPrivate
          );
          // console.log("client Promotion:", clientResponse[0]);
          const clientHasAccess = clientResponse[0]?.clients?.some(client => client.id === auth.user.id);

          if (clientHasAccess) {
            console.log("clientHasAccess", clientHasAccess);
            return clientResponse[0];
          } else {
            setAlertMessage("Invalid promo code");
          }
        }
      } else {
        setAlertMessage("Invalid promo code");
      }
    } catch (error) {
      console.error("Error fetching promotions", error);
    }
  };

  const handlePromoCodeValidation = async () => {

    const promotions = await fetchPromotions();
    setDiscountedPrice(totalPrice);
    setPromotions(promotions);
    // console.log("promotions timeout:", promotions);


    const promo = promotions?.code === promoCode;
    console.log("promo:", promo);

    if (promo) {
      const currentDate = new Date();
      const validityDate = new Date(promotions.date_validity);

      if (currentDate <= validityDate) {
        let newPrice = totalPrice;
        let remainingBalance;
        if (promotions.total_usage === -1) {
          console.log("Total usage is unlimited.");
        }

        if (Array.isArray(promotions.usage) && promotions.usage.length >= 0) {
          const usedPromotions = promotions.usage.filter(usage => usage.is_used);

          if (promotions.total_usage !== -1 && usedPromotions.length >= promotions.total_usage) {
            setAlertMessage("Promo code usage limit reached.");
            return;
          }

          const userUsage = promotions.usage.filter(
            usage => usage.is_used && usage.client_id === auth?.user?.id
          );

          if (promotions.usage_per_customer !== -1 && userUsage.length >= promotions.usage_per_customer) {
            setAlertMessage("You have exceeded the usage limit for this promo code.");
            return;
          }

          if (promotions.type === "FREE_CREDITS") {
            if (promotions.usage.some(usage => usage.value === 0)) {
              setAlertMessage("Promo credit has expired.");
              return;
            }
            const remainingCredits = promotions.usage
              .filter(usage => usage.value > 0)
              .reduce((min, usage) => Math.min(min, usage.value), promotions.amount);

            promotions.amount = remainingCredits;
          }
        }
        // console.log("selectedServices:", selectedServices);
        // console.log("promo product id:", promotions.product_id);

        let updatedTotalPrice = 0;
        let hasValidProduct = false;

        selectedServices.forEach(service => {
          const product = service.product;
          let newProductPrice = product.price;

          const isValidProduct =
            !promotions.product_id ||
            product?.id === promotions.product_id;

          if (isValidProduct) {
            hasValidProduct = true;

            if (promotions.type === "FREE") {
              newProductPrice = 0;
            } else if (promotions.type === "PERCENT_OFF") {
              newProductPrice = product.price - (product.price * promotions.amount) / 100;
            } else if (promotions.type === "FLATT_OFF" || promotions.type === "FREE_CREDITS") {
              newProductPrice = Math.max(0, product.price - promotions.amount);
            }
          }

          updatedTotalPrice += newProductPrice;
        });

        if (!hasValidProduct) {
          setAlertMessage("Promo code is not valid for the selected products");
          return;
        }

        console.log("Updated Total Price:", updatedTotalPrice);


        setDiscountedPrice(updatedTotalPrice);
        setAlertMessage("Promo code applied");

        if (remainingBalance >= 0) {
          console.log(`Remaining balance after discount: ${remainingBalance}`);
        }

      } else {
        setAlertMessage("Promo code has expired");
      }
    }

    const timer = setTimeout(() => setAlertMessage(""), 3000);
    return () => clearTimeout(timer);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const finalSubtotal = promotions?.id ? discountedPrice : selectedServices
      .map(service => service.product.price)
      .reduce((acc, price) => acc + price, 0);
    const productIds = selectedServices.map((service) => service.product.id);
    const response = await BookingService.store({
      date: `${selectedDate?.split('T')[0]} ${selectedTime}`,
      start_before: `${selectedDate?.split('T')[0]} ${selectedTime}`,
      duration: totalDuration,
      longitude: lng,
      latitude: lat,
      address_id: auth?.user?.primary_address_id,
      zip_code: zip_code,
      therapist_id: selectedTherapist?.map(therapist => therapist?.id),
      street: street,
      state: state,
      state_code: state_code,
      country: country,
      country_code: country_code,
      street_number: street_number,
      client_id: auth?.user?.id,
      status: 'CREATED',
      total_fare: selectedServices
        .map(service => service.product.price)
        .reduce((acc, price) => acc + price, 0),
      subtotal: finalSubtotal,
      promocode: promotions?.id ? promoCode : "",
      duo: duo,
      product_id: selectedServices[0].product.id,
      products: productIds,
      description: message,
      phone: phoneNumber

    }, axiosPrivate);

    // if (promotions?.id) {
    //   const remainingBalance = promotions.amount > totalPrice ? promotions.amount - totalPrice : 0;
    //   const promoUsageData = {
    //     is_used: true,
    //     value: remainingBalance >= 0 ? remainingBalance : totalPrice - discountedPrice,
    //     promotion_id: promotions.id,
    //     client_id: auth.user.id,
    //   };

    //   // console.log("Data to be posted to PromotionUsageService:", promoUsageData);

    //   await PromotionUsageService.store(promoUsageData, axiosPrivate);
    //   console.log("Promotion usage data posted successfully.");
    // }


    // navigate('/confirmation');
    console.log({ response })

    loadLightbox(response.data.transaction_data.payment_lightbox, response.data.transaction_data.payment_url);

    // localStorage.removeItem('state');
    // sessionStorage.removeItem('state');
    // sessionStorage.removeItem('chooseServiceState');
    // localStorage.removeItem('chooseServiceState');
    /* if (!termsAccepted) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message);
      setPaymentProcessing(false);
      return;
    }

    console.log('Paiement soumis avec succès ! Redirection vers la page de confirmation.'); */
    // setTimeout(() => {
    //   navigate('/confirmation');
    // }, 2000);

  };
  const loader = useLoading();

  const loadUrl = (paymentUrl) => {
    window.location.replace(paymentUrl);
  }
  const loadLightbox = (paymentLightboxScript, paymentUrl) => {
    loader.start();
    const script = document.createElement('script');
    script.src = paymentLightboxScript;
    script.async = true;
    script.onload = () => {
      window.LightboxCheckoutHandler.startPayment(null, () => { loader.stop(); loadUrl(paymentUrl) });
    };
    document.body.appendChild(script);
  }
  const [handler, setHandler] = useState(null);

  const addMinutesToTime = (time, duration) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + duration);

    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  };

  const formatSelectedDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(date));
  };

  const previousPathname = sessionStorage.getItem("previousPath");
  // sessionStorage.removeItem("previousPath");
  let currentTime = selectedTime;
  // console.log('selectedServices', selectedServices.length, duo)

  return (
    <>
      {previousPathname === "/flypro-booking" ? (
        <>
          <Header />
        </>
      ) : (
        <>
          <MenuHeader />
          <Nav page="payment" />
        </>
      )}
      <div className="content-wrapper">
        <div className="content-wrapper duo-pay">

          {selectedServices &&
            selectedServices.map((service, serviceIndex) => {
              const serviceTherapist = selectedTherapist[serviceIndex % selectedTherapist.length];
              const startTime = currentTime;
              const endTime = addMinutesToTime(startTime, service.product.duration);
              currentTime = endTime;

              return (
                <div className="column" key={service.id}>
                  <div className="summary-box">
                    {serviceTherapist && (
                      <div className="name">
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${serviceTherapist.media.length ? serviceTherapist.media[0].original_url : therapeut1})`,
                          }}
                        ></div>
                        <div className="title">
                          <div className="text-wrapper-2">
                            {serviceTherapist.firstname}
                          </div>
                          <div className="rating">
                            <div className="stars">
                              <div className="text-wrapper-3">
                                <img className="https-app" src={star2} alt="Rating" />
                              </div>
                            </div>
                            <div className="text-wrapper-4">{serviceTherapist?.extra?.rating}/5</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="div-2">
                      <div className="text-wrapper-5">{service.product.name}</div>
                    </div>

                    <div className="div-2">
                      {selectedServices.length < 1 || duo ? (
                        <p className="text-wrapper-5">
                          {formatSelectedDate(selectedDate)} {selectedTime} –{" "}
                          {addMinutesToTime(selectedTime, totalDuration)}
                        </p>
                      ) : (
                        <p className="text-wrapper-5">
                          {formatSelectedDate(selectedDate)} {startTime} – {endTime}
                        </p>
                      )}
                      <div className="text-wrapper-6">{service.product.price} CHF</div>
                    </div>

                    <div className="div-2">
                      <p className="text-wrapper-5">
                        {address?.street} {address?.streetnumber}
                        <br />
                        {address?.zip_code} {address?.city}
                        <br />
                        {!showInput && (
                          phoneNumber
                        )}
                        {showInput && (
                          <div className="edit-phone-payload">
                            <input
                              type="text"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              placeholder="Enter new phone number"
                              className="phone-input"
                            />
                            <button
                              onClick={handleSave}
                              className="btn save-button">Save</button>
                          </div>
                        )}
                      </p>
                      <div className="text-wrapper-3" onClick={() => setShowInput(true)}>
                        <img className="https-app" src={pencil} alt="Edit" />
                      </div>

                    </div>
                    <div className="message-au-flypro">
                      <div className="title-2">
                        <div className="text-wrapper-8">
                          Message au flypro{" "}
                          <img className="https-app" src={questionmark} alt="Info" />
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="Ex: Option de parking, code d'entrée, étage, n° de chambre, info de santé, etc."
                        className="form-message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="column-2">
          <div className="total">
            <div className="price">
              <div className="list-item-2 list-item-3">
                <span>Total (TVA incluse)</span>
                <div className="text-wrapper-6">{totalPrice} CHF</div>
              </div>
              <div className="form-4 form-with-button">
                <input
                  type="text"
                  placeholder="Indiquer un code promo"
                  className="form-5"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button className="form-3" onClick={handlePromoCodeValidation}>
                  Valider
                </button>
              </div>
              {alertMessage && <div className="alert">{alertMessage}</div>}
            </div>

            <div className="checkbox-2">
              <input type="checkbox" className="checkbox" id="cgu" defaultChecked />
              <label htmlFor="cgu" className="accepter-les-CGU-et">
                <span>Accepter </span>
                <span className="text-wrapper-9">les CGU</span>
                <span> et la </span>
                <span className="text-wrapper-9">Politique de Confidentialité</span>
                <span> *</span>
              </label>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="button-validation"
            type="button"
          >
            {discountedPrice.toFixed(2)} CHF
          </button>
        </div>

      </div>
    </>
  );
}

function Payment() {
  return (
    <>
      <PaymentForm totalPriceCHF={120} />
    </>

  );
}

export default Payment;
