import React from "react";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import Rating from "@mui/material/Rating";
import user from "../assets/images/default.png";

const ReviewItem = ({ review }) => {
  const formattedDate = review.created_at
    ? formatDistanceToNow(new Date(review.created_at), { addSuffix: true, locale: fr })
    : "Date inconnue";

  return (
    <div className="review-item">
      <img
        src={review?.creator.media?.length > 0 ? review?.creator?.media[0]?.original_url : user}
        alt={review?.creator?.firstname}
        className="therapist-image"
      />
      <div>
        <div className="user-review-stars">
          <h4>
            {review.creator.firstname || "user"}
          </h4>
          <Rating name="read-only" value={review.rating} precision={0.5} readOnly />
        </div>
        <p className="time-set">{formattedDate.replace("dans", "Il y a")}</p>
        <br />
        <p>{review.comment || "Aucun commentaire."}</p>
      </div>
    </div>
  );
};

export default ReviewItem;
