import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from './Header'
import Nav from './Nav';
import user from '../assets/images/default.png';
import croix from "../assets/images/close.png";
import back from "../assets/images/back.png";
import MenuHeader from './MenuHeader';
import TherapistService from '../services/therapist.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from "react-modal";
import DateTimeSelectorFavPro from './DateTimeSelectorFavPro';
import HeaderTherapist from './HeaderTherapist';
import ReviewList from './ReviewList';
import Informations from './Informations';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';
import CalendarManagementService from '../services/calendar_management.service';
import GeneralSettingService from '../services/general_setting.service';

const customStyles = {
    content: {
        width: "33%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const customStyles2 = {
    content: {
        width: "100%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};


Modal.setAppElement("#root");

function FlyProBooking() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(null);
    const location = useLocation();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [state, setState] = useState(location.state || JSON.parse(sessionStorage.getItem('state') ?? '{}'));
    const { address, selectedServices, duo, selectedTherapist } = state;
    const { lng, lat, zip_code } = address || {};
    const notify = useNotifications();
    const [unavailableDates, setUnavailableDates] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    const [buffertime, setBufferTime] = useState([]);
    const [traveldistance, setTravelDistance] = useState([]);

    const calculateDistance = async () => {
        try {
            const formattedDate = selectedDate.toISOString().split('T')[0];
            const formattedTime = selectedTime ? selectedTime : selectedDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            const finalFormattedDateTime = `${formattedDate} ${formattedTime}`;

            console.log('travelDistance finalFormattedDateTime:', finalFormattedDateTime);
            const params = {
                latitudeA: selectedTherapist[0].latitude,
                longitudeA: selectedTherapist[0].longitude,
                latitudeB: address.lat,
                longitudeB: address.lng,
                departure_time: finalFormattedDateTime,
            };

            const response = await axiosPrivate.post(`/google/map/distance_time`, params);

            console.log('travelDistance:', response.data.duration);
            setTravelDistance(response.data.duration);
        } catch (error) {
            console.error('Error fetching distance:', error);
        }
    };

    const fetchBufferTime = async () => {
        try {
            const bufferTime = await GeneralSettingService.index('setting=in:buffer_before,buffer_after', axiosPrivate);

            const bufferBefore = bufferTime.find(item => item.setting === 'buffer_before')?.value || '0';
            const bufferAfter = bufferTime.find(item => item.setting === 'buffer_after')?.value || '0';

            setBufferTime({ bufferBefore, bufferAfter });
            // console.log('Buffer:', buffertime);
        } catch (error) {
            console.error('Error fetching buffer time:', error);
        }
    };


    useEffect(() => {
        const fetchUnavailableDates = async () => {
            if (selectedTherapist[0]?.id) {
                try {
                    const dates = await fetchCalendarData(selectedTherapist[0].id);
                    const formattedDates = dates.map((date) => new Date(date.split('T')[0]).toDateString());
                    // console.log('formattedDates', formattedDates);
                    setUnavailableDates(formattedDates);
                } catch (error) {
                    console.error('Error fetching unavailable dates:', error);
                }
            }
        };

        fetchUnavailableDates();
        fetchBufferTime();
        // calculateDistance();
    }, [selectedTherapist[0]]);

    const fetchCalendarData = async (therapist) => {
        try {
            const response = await CalendarManagementService.index({ therapist_id: therapist, with: 'therapist.media,therapist.zones,calendars_managements_slots.slot' }, axiosPrivate);
            // console.log('response calendrier :', response);
            const filteredDates = [];
            response.forEach(entry => {
                if (entry.date) {
                    filteredDates.push(entry.date);
                }
            });
            // console.log('filteredDates', filteredDates);


            return filteredDates;

        } catch (error) {
            console.error('Erreur lors de la récupération des données du calendrier :', error);
            throw error;
        }
    };
    useEffect(() => {
        const fetchAvailableHours = async () => {
            if (selectedDate && selectedTherapist[0]?.id) {
                try {
                    const responseSelectedDate = await CalendarManagementService.index(
                        {
                            date: new Date(selectedDate).toLocaleDateString('en-CA').split('T')[0],
                            therapist_id: selectedTherapist[0].id,
                            with: 'therapist.media,therapist.zones,calendars_managements_slots.slot',
                        },
                        axiosPrivate
                    );

                    const filteredSlots = [];
                    responseSelectedDate.forEach((entry) => {
                        if (entry.calendars_managements_slots) {
                            entry.calendars_managements_slots.forEach((slot) => {
                                if (slot.status === 'AVAILABLE' || slot.status === 'OVER_TIME') {
                                    filteredSlots.push({
                                        start_time: slot.slot.start_time,
                                        end_time: slot.slot.end_time,
                                    });
                                }
                            });
                        }
                    });

                    // console.log('filteredSlots:', filteredSlots);
                    // setAvailableHours(filteredSlots);
                    filteredSlots.sort((a, b) => a.start_time.localeCompare(b.start_time));

                    const mergedSlots = [];
                    filteredSlots.forEach((slot) => {
                        if (
                            mergedSlots.length > 0 &&
                            mergedSlots[mergedSlots.length - 1].end_time === slot.start_time
                        ) {
                            mergedSlots[mergedSlots.length - 1].end_time = slot.end_time;
                            mergedSlots[mergedSlots.length - 1].status = slot.status;
                        } else {
                            mergedSlots.push({ ...slot });
                        }
                    });

                    // console.log('mergedSlots1:', mergedSlots);
                    setAvailableHours(mergedSlots);
                } catch (error) {
                    console.error('Error fetching available hours:', error);
                }
            }
        };

        fetchAvailableHours();
        calculateDistance();
    }, [selectedDate, selectedTherapist, axiosPrivate]);
    const totalDuration = selectedServices?.reduce((total, service) => {
        const duration = service?.product?.duration ? parseInt(service.product.duration, 10) : 0;
        return total + duration;
    }, 0);

    const totalPrice = selectedServices?.reduce((total, service) => {
        const price = service?.product?.price ? service.product.price : 0;
        return total + price;
    }, 0);

    useEffect(() => {
        const storedState = JSON.parse(sessionStorage.getItem('state') ?? '{}');
        const now = new Date();
        if (storedState.selectedTherapist) {
            setState(storedState);
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            setState(location.state);
            sessionStorage.setItem('state', JSON.stringify(location.state));
        }
    }, [location.state]);

    useEffect(() => {
        if (!selectedServices || selectedServices.length === 0) {
            navigate('/choose-service');
        } else if (!address) {
            navigate('/choose-address');
        }
    }, [selectedServices, address, navigate]);

    useEffect(() => {
        const now = new Date();
        const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
        now.setMinutes(nextQuarterHour);
        now.setSeconds(0);
        setSelectedTime(now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
        updateDaysToShow(new Date());
    }, []);

    const handleSubmit = (event) => {
        event?.preventDefault();

        const paymentState = {
            address: address,
            duo,
            totalDuration,
            totalPrice,
            selectedServices,
            selectedTherapist,
            selectedDate: selectedDate.toLocaleDateString('en-CA'),
            selectedTime,
        };

        sessionStorage.setItem('state', JSON.stringify(paymentState));
        sessionStorage.setItem('previousPath', location.pathname);
        // console.log("prev:", location.pathname);

        if (!auth || Object.keys(auth).length === 0) {
            sessionStorage.setItem('redirectAfterLogin', '/choose-therapist');
            navigate('/login');
            return;
        }
        navigate('/payment', {
            state: paymentState,
        });
    };

    const handleMonthChange = (direction) => {
        const newDate = new Date(selectedDate || new Date());
        const currentDay = newDate.getDate();
        newDate.setDate(1);
        newDate.setMonth(newDate.getMonth() + direction);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (newDate < new Date(today.getFullYear(), today.getMonth(), 1)) return;

        const daysInNewMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
        newDate.setDate(Math.min(currentDay, daysInNewMonth));

        setSelectedDate(newDate);
        updateDaysToShow(newDate);
    };

    const handleDayChange = (direction) => {
        const container = document.querySelector('.row-day');
        if (!container) return;

        const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
        const containerWidth = container.clientWidth;
        const visibleItems = Math.floor(containerWidth / dayWidth);
        const daysToScroll = Math.max(1, visibleItems - 0.5);

        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + direction * daysToScroll);

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (newDate < today) {
            newDate.setDate(today.getDate());
        };
        setSelectedDate(newDate);
        updateDaysToShow(newDate);

    };
    const redirectToPartnerHotels = () => {
        window.location.href = '/hotels';
    };

    function handleDayClick(date) {
        setSelectedDate(date);

        const now = new Date();
        let initialTime;

        if (date.getDay() === 0) {
            setShowPopup(true);
        }

        if (date?.toDateString() === now?.toDateString()) {
            const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
            now.setMinutes(nextQuarterHour);
            now.setSeconds(0);
            initialTime = now;
        } else {
            initialTime = new Date(date);
            initialTime.setHours(8, 0, 0, 0);
        }

        setSelectedTime(initialTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
    }
    function handleHourClick(hour) {
        setSelectedTime(hour);
    }

    const getMonthName = (date) => {
        // const date = new Date();
        // date.setMonth(monthIndex);
        // console.log('monthIndex',monthIndex)
        return date.toLocaleString('fr-FR', { month: 'long' });
    };

    const getDayName = (date) => {
        return date.toLocaleString('fr-FR', { weekday: 'short' });
    };

    const scrollLeft = () => {
        const container = document.querySelector('.row-day');
        if (!container) return;

        const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
        const visibleItems = Math.floor(container.clientWidth / dayWidth);
        const scrollDistance = dayWidth * (visibleItems - 0.5);

        container.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
    };

    const scrollRight = () => {
        const container = document.querySelector('.row-day');
        if (!container) return;

        const dayWidth = container.querySelector('.btn-hour')?.offsetWidth || 0;
        const visibleItems = Math.floor(container.clientWidth / dayWidth);
        const scrollDistance = dayWidth * (visibleItems - 0.5);

        container.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    };


    const getInitialDaysToShow = () => {
        const days = [];
        const today = new Date();
        for (let i = 0; i < 9; i++) {
            const day = new Date(today);
            day.setDate(today.getDate() + i);
            days.push(day);
        }
        return days;
    };

    const [daysToShow, setDaysToShow] = useState(() => getInitialDaysToShow());
    const [showPopup, setShowPopup] = useState(false);

    const updateDaysToShow = (baseDate) => {
        const days = [];
        for (let i = 0; i < 9; i++) {
            const day = new Date(baseDate);
            day.setDate(baseDate.getDate() + i);
            days.push(day);
        }
        setDaysToShow(days);
    };

    const getNextHourSlots = (date) => {
        const now = new Date();
        const hours = [];

        if (date?.toDateString() === now?.toDateString()) {
            const minutes = now.getMinutes();
            const nextQuarterHour = Math.ceil(minutes / 15) * 15;
            now.setMinutes(nextQuarterHour);
            now.setSeconds(0);

            for (let i = 0; i < 57; i++) {
                const slot = new Date(now.getTime() + i * 15 * 60000);
                if (slot.getHours() >= 8 && slot.getHours() < 22) {
                    hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
                }
            }
            // // console.log('hours1',hours)

        } else {
            const startOfDay = new Date(date);
            startOfDay.setHours(8, 0, 0, 0);

            for (let i = 0; i < 57; i++) {
                const slot = new Date(startOfDay.getTime() + i * 15 * 60000);
                if (slot.getHours() >= 8 && slot.getHours() < 22) {
                    hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
                }
            }
            // // console.log('hours2',hours)
        }

        return hours;
    };

    const hours = getNextHourSlots(selectedDate);
    return (
        <>
            <Header />

            <form className="form-masseur" onSubmit={handleSubmit}>
                <div className='container container-therapeut'>
                    <h2>Choisissez la date</h2>
                </div>

                <div className='container container-therapeut'>
                    <DateTimeSelectorFavPro
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        handleMonthChange={handleMonthChange}
                        handleDayChange={handleDayChange}
                        handleDayClick={handleDayClick}
                        handleHourClick={handleHourClick}
                        daysToShow={daysToShow}
                        hours={hours}
                        totalPrice={totalPrice}
                        scrollLeft={scrollLeft}
                        scrollRight={scrollRight}
                        getMonthName={getMonthName}
                        getDayName={getDayName}
                        unavailableDates={unavailableDates}
                        availableHours={availableHours}
                        buffertime={buffertime}
                        totalDuration={totalDuration}
                        traveldistance={traveldistance}
                    />
                </div>

                <div className='btn-bottom'>
                    <button type="submit" className="btn btn-primary">
                        Suivant
                    </button>
                </div>

            </form>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>No at-home service on Sundays, but our services are available in our partner hotel.</p>
                        <button onClick={redirectToPartnerHotels} className="btn redirect-button">
                            Choose a Partner Hotel
                        </button>
                        <button onClick={() => setShowPopup(false)} className="btn close-button">
                            Close
                        </button>
                    </div>
                </div>
            )}

        </>
    );
}

export default FlyProBooking;
